import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TokenStorageService } from '../shared/loginservices/token-storage.service';
import { MRDService } from './mrd.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import {MRD_URL} from './mrdConstants'
import { InpatientService } from '../viewinpatient-module/inpatient.service';
import { PreviewComponent } from '../print-management/preview/preview.component';
import { MatDialog } from '@angular/material/dialog';
import { NursestationsService } from '../ward-management/nursestations.service';

@Component({
  selector: 'app-mrd',
  templateUrl: './mrd.component.html',
  styleUrls: ['./mrd.component.css']
})

export class MRDComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /* advanced search */
  advSearchForm: FormGroup;
  public userInfo: any = {};
  patientsList: any = [];
  totalPatientsList: any = ''
  patientsData = new MatTableDataSource(this.patientsList);
  columnsToDisplay = ['URM', 'IPNR', 'PATIENTNAME', 'GENDERAGE', 'DOB', 'PARENTGUARDIAN', 'MOBILENO', 'DISCHARGEDATE', 'DISCHARGETYPE', 'ACTION'];
  files: string[] = [];
  selectedUmrNo: string = '';
  patientDocuments:any = []
  mrdUrls:any = ''
  public dischargeSummaryPatientInfo: any = '';
  public existingDischargeSummaryInfo:any = '';
  icdPatientDetails: any = {}
  patientName: any;

  constructor(
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
    private MRDService: MRDService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private inpatientService: InpatientService,
    public dialog: MatDialog,
    private nursestationsService: NursestationsService) 
    { 
      this.userInfo = this.tokenService.getUser();
      this.mrdUrls = MRD_URL
    }

  ngOnInit(): void {
    this.advSearchForm = this.formBuilder.group({
      PATIENTNAME: [''],
      UMRID: [''],
      IPNR: [''],
      ADMISSIONDATE: [''],
      CONSULTDOCTOR: [''],
      SPONSER: ['']
    });
    this.getPatientsList();
    
  }

  ngAfterViewInit(): void {
    this.getPatientsList();
    this.columnsToDisplay = ['URM', 'IPNR', 'PATIENTNAME', 'GENDERAGE', 'DOB', 'PARENTGUARDIAN', 'MOBILENO', 'DISCHARGEDATE', 'DISCHARGETYPE', 'ACTION'];
  }

  onSearch(): void {
    this.getPatientsList();
  }

  getPatientsList(): void {
    if(this.advSearchForm.controls.PATIENTNAME.value.length > 2 || this.advSearchForm.controls.PATIENTNAME.value.length == 0)
     {
       this.patientName = this.advSearchForm.controls.PATIENTNAME.value;
     }
    const inutReq = {      
      start: 0,
      limit: 10,
      ser_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.advSearchForm.controls.UMRID.value,
      sear_ip_no: this.advSearchForm.controls.IPNR.value,
      sear_patient: this.patientName,

      sear_admission_date: this.advSearchForm.controls.ADMISSIONDATE.value,
      sear_consult_doctor: this.advSearchForm.controls.CONSULTDOCTOR.value,
      sear_ptmid: this.advSearchForm.controls.SPONSER.value,
      admission_date_order: '',
      doctor_name_order: '',
      ipno_order: '',
      sponser_order: ''
    };

    this.inpatientService.DischargepatientsList(inutReq)
      .subscribe(
        (response) => {
          console.log("response", response)
          this.patientsList = response
          this.totalPatientsList = response.totalRecords
          this.patientsData = new MatTableDataSource(this.patientsList);
          this.patientsData.sort = this.sort;
          this.patientsData.paginator = this.paginator;
          
        },
        (err) => {
          console.log("getPatientsList -> mrd.component.ts")
        }
      )
  }

  uploadFile(uploadFilePopup, patientInfo): void
  {
    
    if(this.userInfo.hospitalShortName == 'DPMTA') {
      let uploadUrl = 'https://readycloud.netgear.com/client/index.html#page=access&path=%2Fdata%2FCASESHEETS%2Fscan%202021%2FNew%20folder2&deviceid=Storage_UZopV_BCA5113A5454';
      this.openWindow(uploadUrl);
    } else {
      this.selectedUmrNo = patientInfo.P_ID
      this.modalService.open(uploadFilePopup, {size: 'lg'});
    }

  }

  openWindow(url) {

    let newwindow = window.open(url, '_blank');
    if(window.focus) { newwindow.focus(); }
    return false;

  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  fileUpload()
  {
    const formData: FormData = new FormData();
    for (var i = 0; i < this.files.length; i++) { 
      formData.append("files[]", this.files[i]);
      formData.append("createdId", this.userInfo.username);
      formData.append("umrNo", this.selectedUmrNo);
    }

    this.MRDService.filesUpload(formData)
      .subscribe(
        (res) => {
          this.modalService.dismissAll();
          this.toastr.success('Files successfully uploaded');
          this.files = [];
          this.selectedUmrNo = '';
        },
        (err) => {
          this.modalService.dismissAll();
          this.files = [];
          this.selectedUmrNo = ''
          this.toastr.error('Something went wrong..!');
          console.log("Error", err)
        }
      )
  }

  viewPatientDocuments(viewPatientDocumentPopup, patientInfo): void
  {
    let inputRequest = {
      umrNo: patientInfo.P_ID
    }
    this.MRDService.getPatientDocuments(inputRequest)
      .subscribe(
        (res) => {
          this.patientDocuments = res
          this.modalService.open(viewPatientDocumentPopup, {size: 'lg'})
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      )
  }

  makeDropZoneEmpty()
  {
    this.files = [];
    this.modalService.dismissAll()
  }

  finalBillprint(typeOfBill, patientInfo): any {
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'summarybill',
        ipno: patientInfo.IP_NR,
        umrno: patientInfo.P_ID,
        typeofbill: typeOfBill
      }
    });
  }

  printDischargeSummary(dischargeSummaryPrintPopup, patientData)
  {
    let data = {
      UMRNo: patientData.P_ID,
      IpNr: patientData.IP_NR,
      PatientName: patientData.PATIENT_NAME,
      GenderAge: patientData.AGE,
      ConsultDoctor: patientData.CONSULT_DOCTOR,
      address: patientData.address,
      refDoctorName: patientData.refDoctorName,
      admissionDate: patientData.IP_ADMISSION_DATE,
      dischargeDate: patientData.IP_DISCHARGE_DATE,
      headingTitle: patientData.HEADING_TITLE
    }
    this.dischargeSummaryPatientInfo = data;
    this.getDischargeSummary(patientData.IP_NR, dischargeSummaryPrintPopup);
  }

  getDischargeSummary(ipNr, dischargeSummaryPrintPopup) 
  {  
      let inputRequest = {
        ipNr: ipNr
      }
      this.nursestationsService.getDischargeSummaryInfo(inputRequest)
        .subscribe(
          (dischargeSummaryInfo: any) => {
            this.dischargeSummaryPatientInfo.dischargeType = dischargeSummaryInfo.DISCHARGE_TYPE
            this.dischargeSummaryPatientInfo.headingTitle = dischargeSummaryInfo.HEADING_TITLE
            this.dischargeSummaryPatientInfo.address = dischargeSummaryInfo.ADDRESS
            this.dischargeSummaryPatientInfo.refDoctorName = dischargeSummaryInfo.DOCTOR_NAME
            this.dischargeSummaryPatientInfo.ConsultDoctor = dischargeSummaryInfo.CONSULTANT_DOC_NAME
            this.existingDischargeSummaryInfo = dischargeSummaryInfo;
            this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg'});
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        )
  }

  actionIcdPop(icdPopup, patientData)
  {
    this.icdPatientDetails = patientData
    this.modalService.open(icdPopup, { size: 'xl' })
  }

  popupClose()
  {
    this.modalService.dismissAll()
  }

}
