import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { NursestationsService } from 'src/app/ward-management/nursestations.service';
import { WardService } from 'src/app/ward-management/ward.service';

@Component({
  selector: 'app-insurance-patients',
  templateUrl: './insurance-patients.component.html',
  styleUrls: ['./insurance-patients.component.css']
})
export class InsurancePatientsComponent implements OnInit {
  @ViewChild('creditBillSubmissionPopup') creditBillSubmissionPopup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userInfo: any;
  advSearchForm: any;
  patientsList: any = [];
  patientsData = new MatTableDataSource(this.patientsList);
  columnsToDisplay = [
    'IPNUM',
    'NAMEGENDERAGE',  
    'SPONSOR',
    'BILLAMOUNT',
    'ADMISSIONDATE',
    'DISCHARGETYPE',
    'DISCHARGEDDATE',
    'ACTION',
  ];
  selfPayList: any;
  insuranceList: any;
  patients: any = {};
  icdPatientDetails: any;
  patientIPNo: any;
  patientUMRNo: any;
  dischargeSummaryPatientInfo: any;  
  existingDischargeSummaryInfo: any = '';
  patientQuickInformation: any;
  existingRecord = false;
  patientName: any;
  loading: boolean;
  selectedIPPatientNO: any;
  seletedPatientURMNo: any;
  rolesInfo: any;

  constructor(private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private nursestationsService: NursestationsService,
    private wardService: WardService,
    private toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog) 
    {
      if (this.tokenService.getToken()) {
        this.rolesInfo = this.tokenService.getUser().rolesInfo;
      }
      this.userInfo = this.tokenService.getUser();
     }

  ngOnInit(): void {
    this.advSearchForm = this.formBuilder.group({
      IPNO: [''],
      PATIENTNAME: [''],      
      UMRID: [''],
      FROMDATE: [''],
      TODATE: ['']  
    });
    this.loadPatientsList();
  }

  backNav(): void {
    this.router.navigate(['/billing']);
  }

  onSearch(): void {
    this.loadPatientsList();
  }

  /* to load the patients list */
  loadPatientsList(){
    if(this.advSearchForm.controls.PATIENTNAME.value.length > 2 || this.advSearchForm.controls.PATIENTNAME.value.length == 0)
     {
       this.patientName = this.advSearchForm.controls.PATIENTNAME.value;
     }
    const input = {
      "start": 0,
      "limit": 10,
      "ser_hospitalid": this.userInfo.hospitalID,
      "sear_umr_no": this.advSearchForm.controls.UMRID.value,
      "sear_ip_no": this.advSearchForm.controls.IPNO.value,
      "sear_patient": this.patientName,
      "fromDate": this.advSearchForm.controls.FROMDATE.value,
      "toDate": this.advSearchForm.controls.TODATE.value,
      "sear_admission_date": "",
      "sear_consult_doctor": "",
      "sear_ptmid": 2,
      "admission_date_order":  "",
      "doctor_name_order": ""
      }
      this.inpatientService.DischargepatientsList(input).subscribe((response: any)=>{
        this.patients = response;
        this.patientsList = this.patients;
        //console.log(this.patientsList);
        this.patientsData = new MatTableDataSource(this.patientsList);
      },(err) =>{
        this.patientsData = new MatTableDataSource([]);
      });
  }

  patientQuickInfo(patientQuickInfoPopup, patient): void {
    this.patientQuickInformation = patient;
    this.modalService.open(patientQuickInfoPopup, { size: 'xl' });
  }

  getNextData(currentSize, offset, limit22): void {
    const inutReq = {
      start: offset * limit22,
      limit: limit22,
      ser_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.advSearchForm.controls.UMRID.value,
      sear_ip_no: this.advSearchForm.controls.IPNO.value,
      sear_patient: this.advSearchForm.controls.PATIENTNAME.value,
      sear_admission_date: this.advSearchForm.controls.ADMISSIONDATE.value,
    };

    this.inpatientService.patientsList(inutReq).subscribe((response) => {
      this.loading = false;

      this.patientsList.length = currentSize;
      this.patientsList.push(...response.data);

      this.patientsList.length = response.totalRecords;

      this.patientsData = new MatTableDataSource<any>(this.patientsList);
      // this.patientsData._updateChangeSubscription();

      this.patientsData.paginator = this.paginator;
    });
  }

  pageChanged(event): void {
    this.loading = true;

    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;

    const previousIndex = event.previousPageIndex;

    const previousSize = pageSize * pageIndex;

    this.getNextData(previousSize, pageIndex.toString(), pageSize.toString());
  }

  actionRouting(value: any, patient: any){
    const val = value;
    this.selectedIPPatientNO = patient?.IP_NR;
    this.seletedPatientURMNo = patient?.P_ID;
    if (val === 'creditBill') {
      this.openCreditBillPopup(this.creditBillSubmissionPopup);
    }
  }

  openCreditBillPopup(creditBillSubmissionPopup): void {
    this.modalService.open(creditBillSubmissionPopup, { size: 'xl' });
  }

  popupClose(){
    this.modalService.dismissAll();
  }

  clearSearchParameters(){
    this.advSearchForm.reset();
  }
}
