import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { Editor, Validators } from 'ngx-editor';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { LabService } from '../../lab-module/lab.service';
import { 
        OPSampleCollection,
        IPSampleCollection, 
        OPPatientServices, 
        IPPatientServices,
        OPLabServiceRequest,
        IPLabServiceRequest,
        LabServiceResultEntry,
        AddLabServiceResult,
        SampleIdsService,
        SampleIdResultService,
        ReportingDoctors,
        DoctorSignature,
        GetAllLabService,
        GetAllWards
       } from '../../lab-module/labconstants';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { couldStartTrivia } from 'typescript';

@Component({
    selector: 'app-sample-collection',
    templateUrl: './sample-collection.component.html',
    styleUrls: ['./sample-collection.component.css']
  })
export class SampleCollectionComponent implements OnInit {


  @ViewChild('OPPreAuthPopup') OPPreAuthPopup;
  @ViewChild('IPPreAuthPopup') IPPreAuthPopup;
  @ViewChild('OPResultEntryPreAuthPopup') OPResultEntryPreAuthPopup;
  @ViewChild('IPResultEntryPreAuthPopup') IPResultEntryPreAuthPopup;

  @ViewChild(MatSort, { static: false }) opSort: MatSort;
  @ViewChild('opPaginator', { read: MatPaginator }) opPaginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) ipSort: MatSort;
  @ViewChild('ipPaginator', { read: MatPaginator }) ipPaginator: MatPaginator;

  opcolumnsToDisplay = ['UMRNUM','VISITID','NAME','GENDERAGE','REFERREDBY','REQUESTDATE','REQUESTTIME','ACTION'];
  opSampleCollectioninfo: any = [];
  opSampleCollection = new MatTableDataSource(this.opSampleCollectioninfo);

  ipcolumnsToDisplay = ['UMRNUM','IPNUM','NAME','GENDERAGE','REFERREDBY','REQUESTDATE','REQUESTTIME','ACTION'];
  ipSampleCollectioninfo: any = [];
  ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
  
  public SampleCollectionURL:any ="";
  public labServiceRequestURL:any = "";
  public bodyDisplay = 'OP';
  public serviceIndex = 0;
  public resultIndex = 0;
  public searchType = "Pending";
  public userInfo: any = {};
  public patientInfo: any = [];
  public servicesTypeList: any = {};
  public servicesList: any = {};
  public sampleServices = [];
  public servicesSequence: any = {};
  public totalCount = 0;
  public pendingCount = 0;
  public completedCount = 0;
  public resultEntryCount = 0;
  public labResultSequenceNo :any = [];
  public labResultStausSequenceNo :any = [];
  public labResultServices : any = [];
  public labResultServicesList : any = [];
  public serviceSubTestList : any = [];
  public reportingDoctors : any = [];
  public selectedreportingDoctor = "";
  public selectedreportingDoctorID = 0;
  public selectedreportingDoctorDesignation = "";
  public selectedDoctor = "";
  public doctorSignature = "";
  public alphabetIndex = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
  /* advanced search */
  advSearchForm: FormGroup;
  Control: AbstractControl;
  advancedSearch: any = {

  };
  public labSampleIds = [];
  public labSampleTextEditor = false;
  public labSampleResults = [];
  public reportDoctorsList = [];
  public selectedReportDoctor = "";

  public serviceList = [];
  public subTestHeading = [];
  public subTestServices = [];
  public subTestInnerServices = [];
  public allServicesList = [];
  public allServicesData = [];

  public labResBtn = false;
  public labResPrintBtn = false;

  public labServiceResult:any = []; 

  public labServiceType:any = "";
  public labAllServiceResult:any = [];
  public labServicePrint:any = [];
  public labReportingDoctors:any = [];
  public allLabServices:any = [];
  public wardListArr:any = [];

  form: FormGroup;
  loading = true;
  editor: Editor;
  html: '';
  departmentName: any;
  sortNO: any;
  labAllServices = {};
  
  constructor(
    private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private labService: LabService,
    private inpatientService: InpatientService
   ) {
    this.userInfo = this.tokenService.getUser();

  }
  public labSequenceObj = {
    "patientType": "",
    "visitID" : 0,
    "patientID" : '', 
    "ipNr" : 0,
    "sampleId" : 0
  }
 

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl("", Validators.required())
      // doctorName: new FormControl("", Validators.required()),
      // doctorDesignation: new FormControl("", Validators.required())
    });
    this.advSearchForm = this.formBuilder.group({
      URMNO : [''],
      PATIENTNAME : [''],
      VISITID : [''],
      IPNUM : [''],
      LABDATE : [''],
      SERVICENAME:[''],
      WARDNAME:['']
    });
    this.getAllLabServices();
    this.getAllWardsList();
    this.loadSampleCollectionList();
  }

  get doc(): AbstractControl {
    return this.form.get("editorContent").value;
  }

  public onSearch(): void {
    if(this.advSearchForm.controls.URMNO.value || this.advSearchForm.controls.PATIENTNAME.value || this.advSearchForm.controls.VISITID.value || this.advSearchForm.controls.IPNUM.value ||  this.advSearchForm.controls.LABDATE.value ||  this.advSearchForm.controls.SERVICENAME.value ||  this.advSearchForm.controls.WARDNAME.value) {
      // this.searchType = "All";
      this.loadSampleCollectionList();
    }
  }

  public OnpreAuthPopup(collectionPopup): void {
    this.modalService.open(collectionPopup, { size: 'xl' });
  }

  public onClosePopup():void {
    this.modalService.dismissAll();
  }

  public clearSearchFields():void{
    this.advSearchForm = this.formBuilder.group({
      URMNO : [''],
      PATIENTNAME : [''],
      VISITID : [''],
      IPNUM : [''],
      LABDATE : [''],
      SERVICENAME:[''],
      WARDNAME:['']
    });
  }

  public getAllLabServices():void {
    const inputReq = {
      "searchType": this.searchType,
      "patientType" : this.bodyDisplay,
      "hospitalID": this.userInfo.hospitalID
    }
    this.labService.getAllLabServiceList(GetAllLabService,inputReq)
    .subscribe(response => {
      // console.log(response);
        this.allLabServices = response;
      });
  }

  public getAllWardsList():void {
    const inputReq = {
      "searchType": this.searchType,
      "patientType" : this.bodyDisplay,
      "hospitalID": this.userInfo.hospitalID
    }
    this.labService.getAllWardsList(GetAllWards,inputReq)
    .subscribe(response => {
      console.log(response);
        this.wardListArr = response;
      });
  }

  /** get patients list from B-end */
  public loadSampleCollectionList(): void {
   
    const inutReq = {
      "searchType": this.searchType,
      "hospitalID": this.userInfo.hospitalID,
      "patientID": this.advSearchForm.controls.URMNO.value,
      "patientName": this.advSearchForm.controls.PATIENTNAME.value,
      "visitID": this.advSearchForm.controls.VISITID.value,
      "ipNum": this.advSearchForm.controls.IPNUM.value,
      "labDate": this.advSearchForm.controls.LABDATE.value,
      "serviceName": this.advSearchForm.controls.SERVICENAME.value,
      "wardName": this.advSearchForm.controls.WARDNAME.value

    };
    this.opSampleCollectioninfo = this.ipSampleCollectioninfo = [];

    this.SampleCollectionURL = (this.bodyDisplay == "OP") ? OPSampleCollection : IPSampleCollection;

    this.labService.getSampleCollection(this.SampleCollectionURL, inutReq)
    .subscribe(response => {
        if (this.bodyDisplay == "OP") {    
          this.opSampleCollectioninfo = response.data;
        } else{
          this.ipSampleCollectioninfo = response.data;
        }
        this.opSampleCollection = new MatTableDataSource(this.opSampleCollectioninfo);
        this.opSampleCollection.sort = this.opSort;
        this.opSampleCollection.paginator = this.opPaginator;

        this.ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
        this.ipSampleCollection.sort = this.ipSort;
        this.ipSampleCollection.paginator = this.ipPaginator;

        this.totalCount = response.labServicesCount.Total;
        this.pendingCount = response.labServicesCount.Pending;
        this.completedCount = response.labServicesCount.Completed;
        this.resultEntryCount = response.labServicesCount.ResultEntry;

    });
  } 

 // disable or enable save button
 public checkServiceIsActive(serviceArr):any{
    this.servicesSequence[serviceArr[0].sequenceNo] = false;
    serviceArr.forEach(element => {
      if(this.bodyDisplay == "IP")
      {
        if(element.serviceAccept && !element.serviceStatus){
          this.servicesSequence[serviceArr[0].sequenceNo] = true;
        }
        else if(element.serviceReject && !element.serviceStatus && element.serviceRemarks) {
          this.servicesSequence[serviceArr[0].sequenceNo] = true;
        }
      } else {
        if(element.serviceRequest && !element.serviceStatus){
          this.servicesSequence[serviceArr[0].sequenceNo] = true;
        }
      }
    });
  }

  // Result Entry save button disable or enable 
  public checkResultServiceIsActive(labServices):any{
    console.log(labServices);
    
    // return "";
    this.labResBtn = true;

    // if(labServices.RESULT_NUMERIC_VALUE == "" || labServices.RESULT_NUMERIC_VALUE == null) {
    //   this.labResBtn  = false;
    // } else {
      let LreId = (this.bodyDisplay == "OP") ? labServices.LREO_ID :labServices.LREI_ID;
      let serviceID = labServices.SERVICE_TYPE_ID;
    
      if(serviceID && LreId)
      {
        this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_NUMERIC_VALUE'] =  labServices.RESULT_NUMERIC_VALUE;
        this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_UNITS'] =  labServices.RESULT_UNITS;
        this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_NORMAL_RANGES'] =  labServices.RESULT_NORMAL_RANGES;
        this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_REMARKS'] =  labServices.RESULT_REMARKS;
        this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_METHOD'] =  labServices.RESULT_METHOD;

        let minVal = parseFloat(this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['MIN_VALUE']);
        let maxVal = parseFloat(this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['MAX_VALUE']);
        // alert(maxVal+""+parseFloat(labServices.RESULT_NUMERIC_VALUE));
        if(parseFloat(labServices.RESULT_NUMERIC_VALUE) > maxVal) {
          this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_ALERT']  = "H";
        }else if(parseFloat(labServices.RESULT_NUMERIC_VALUE) < minVal) {
            this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_ALERT']  = "L";
          
        }else {
          this.labAllServiceResult['SERVICE_ID_'+serviceID]['LR_ID_'+LreId]['RESULT_ALERT']  = "";
        }
      }
    // }

    // this.labAllServiceResult.forEach(element => {

    //   if(element.lsorID == labServices.lsorID) {
    //     element.resultNumericValue = labServices.resultNumericValue;
    //     element.resultRemarks = labServices.resultRemarks;
    //     element.ResultMethod  = labServices.ResultMethod;
    //     element.ResultRange   = labServices.ResultRange;
    //     element.ResultUnits   = labServices.ResultUnits;
    //   } 

      // if(element.resultNumericValue == "" || element.resultNumericValue == null) {
      //   this.labResBtn  = false;
      // }
      // console.log(this.labResBtn + " == " +element.lsorID + " == " +element.resultServiceStatus +"=="+element.resultNumericValue);
    // });

}

  // geting patient labservice list 
  public getPatientLabServices(labPatientInfo:any,type:any):any{
    let inpObj = {};
    let preAuthPopup = "";
    let labPatientServicesURL ="";
    // console.log("SortNO",this.sortNO);
    console.log(labPatientInfo);
    if(type == "OP") {
      inpObj = {
        "visitID": labPatientInfo.visitID,
        "patientID": labPatientInfo.patientID,
        "searchType": this.searchType,
        "labRequestDate": this.advSearchForm.controls.LABDATE.value,
        "serviceName": this.advSearchForm.controls.SERVICENAME.value,
        "wardName": this.advSearchForm.controls.WARDNAME.value
      }
      this.patientInfo = labPatientInfo;
      labPatientServicesURL = OPPatientServices;
      preAuthPopup = this.OPPreAuthPopup;
    } else {
      inpObj = {
        "ipNum": labPatientInfo.ipNum,
        "patientID": labPatientInfo.patientID,
        "searchType": this.searchType,
        "labRequestDate": this.advSearchForm.controls.LABDATE.value,
        "serviceName": this.advSearchForm.controls.SERVICENAME.value,
        "wardName": this.advSearchForm.controls.WARDNAME.value
      }
      const inutReq = {
        sear_umr_no: labPatientInfo.patientID,
        sear_ip_no: labPatientInfo.ipNum,
        sear_hospitalid: this.userInfo.hospitalID,
      };
      this.inpatientService.patientInfo(inutReq).subscribe(
        (response) => {
          this.patientInfo = response;
      });
      labPatientServicesURL = IPPatientServices;
      preAuthPopup = this.IPPreAuthPopup;
    }
    
    this.labService.getLabPatientServiceByID(labPatientServicesURL, inpObj)
      .subscribe(response => {
        console.log(response);
        this.servicesList = response.data;
        this.serviceSubTestList = response.subTests;
        if(response.data.length != 0) {
          this.serviceIndex     = 0;
          this.sampleServices   = response.data[0];
          this.servicesSequence = response.sequence;
          this.OnpreAuthPopup(preAuthPopup);
        }
    });
  }

  public setServicesIndex(index: any) {
    this.serviceIndex = index;
    if (index !== undefined) {
      this.sampleServices = this.servicesList[index];
    }
  }
  // updateing labservice request status
  public saveServiceRequests(serviceList:any):any{
    const labServiceList = [];
    if(this.bodyDisplay == "IP")
    {
      serviceList.forEach(element => {
        if((element.serviceAccept || element.serviceReject) && !element.serviceUpdated) {
          element.servieAcceptedBy = this.userInfo.username;
          element.serviceStatus  = (element.serviceReject) ? "Rejected" : "Accepted";
          this.servicesSequence[element.sequenceNo] = false;
          element.subTestInfo = this.serviceSubTestList[element.sequenceNo][element.smID];
          labServiceList.push(element);
        }
      });
    } else {
      serviceList.forEach(element => {
        if(element.serviceRequest && !element.serviceUpdated) {
          element.servieAcceptedBy = this.userInfo.username;
          element.serviceRequest  =  "Accepted";
          element.serviceStatus = true;
          this.servicesSequence[element.sequenceNo] = false;
          element.subTestInfo = this.serviceSubTestList[element.sequenceNo][element.smID];
          labServiceList.push(element);
        }
      });
    }

    const labObj ={
      'services': labServiceList
    }

    this.labServiceRequestURL = (this.bodyDisplay == "OP") ?  OPLabServiceRequest : IPLabServiceRequest;
    this.labService.saveLabServicesRequest(this.labServiceRequestURL, labObj)
    .subscribe(response => {
      if(response.status)
      {
        this.loadSampleCollectionList();
      }
    });
  } 
  
  public getResultEntryServices(labPatientInfo:any,patientType:any):void {
    let visitID = 0;
    let ipNr = 0;
    let resultEntryPopup = "";
    if(patientType == "OP") {
      this.patientInfo = labPatientInfo;
      visitID = labPatientInfo.visitID;
      resultEntryPopup = this.OPResultEntryPreAuthPopup;
    } else {
      const inutReq = {
        sear_umr_no: labPatientInfo.patientID,
        sear_ip_no: labPatientInfo.ipNum,
        sear_hospitalid: this.userInfo.hospitalID,
      };
      ipNr = labPatientInfo.ipNum;
      this.inpatientService.patientInfo(inutReq).subscribe(
        (response) => {
          this.patientInfo = response;
          this.patientInfo['patientID'] = labPatientInfo.patientID;
      });
      resultEntryPopup = this.IPResultEntryPreAuthPopup;
     
    }
    const labResultObj = {
      "patientType": patientType,
      "visitID" : visitID,
      "patientID" : labPatientInfo.patientID, 
      "ipNr" : ipNr,
      "labRequestDate": this.advSearchForm.controls.LABDATE.value,
      "serviceName": this.advSearchForm.controls.SERVICENAME.value,
      "wardName": this.advSearchForm.controls.WARDNAME.value
    }
    this.labService.getLabServiceResultEntry(LabServiceResultEntry, labResultObj)
    .subscribe(response => {
      this.labResultServicesList = response.data;
      if(response.status) {
        this.resultIndex = 0;
        this.labResultServices   = response.data[response.sequenceNums[0]];
        this.labResultStausSequenceNo = response.data['sequence'];
        this.labResultSequenceNo = response.sequenceNums;
      }
      this.OnpreAuthPopup(resultEntryPopup);
      // console.log(this.patientInfo);
    });
  }

  public addLabResultsData(labResults:any):void{
    const labObj = {
      "paatientType" : this.bodyDisplay,
      "labServices"  : this.labAllServiceResult,
      "texteditor"   : this.form.value.editorContent,
      "sampleId"     : this.labSampleIds[this.resultIndex],
      "textEditorStatus" :  this.labSampleTextEditor,
      "reportingDoctorId" : this.selectedreportingDoctorID
    }

    this.labService.saveLabResultEntryServices(AddLabServiceResult,labObj).subscribe(response => {
      // console.log(response);
      this.labResBtn = false;
      this.labResPrintBtn = response.printStatus;
      this.html = this.form.value.editorContent;
      this.selectedDoctor = this.labReportingDoctors[this.selectedreportingDoctorID];
      this.getReportingDoctors(this.selectedDoctor);
     
    });
  }

  public requiredDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return funYear + '-' + this.checkingTwoDigits(funMonth) + '-' + this.checkingTwoDigits(funDay);
  }

  public checkingTwoDigits(info): string {
    const twoDig = info.toString().length < 2 ? '0' + info : '' + info;
    return twoDig;
  }

  public getDate() {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }
    public printLabResults():void {
    const htmlele = document.getElementById('labprint-section');
    htmlele.style.display = 'block';
    const sheet = document.createElement('style');
          sheet.innerHTML = '@page { margin-top: 2in; margin-bottom: 0.8in}';
          document.getElementById('labprint-section').appendChild(sheet);

    this.printerService.printDiv('labprint-section');
    setTimeout(() => {
     const htmlele = document.getElementById('labprint-section');
       htmlele.style.display = 'none';
     }, 1000);
  } 

  public printTexteditorData():any {
    const htmlele = document.getElementById('labprint-texteditor');
    htmlele.style.display = 'block';
    this.printerService.printDiv('labprint-texteditor');
    setTimeout(() => {
     const htmlele = document.getElementById('labprint-texteditor');
     htmlele.style.display = 'none';
     }, 1000);
  } 

  public getLabResultSampleIds(labPatientInfo:any,patientType:any):void {
    let visitID = 0;
    let ipNr = 0;
    let resultEntryPopup = "";
    if(patientType == "OP") {
      this.patientInfo = labPatientInfo;
      visitID = labPatientInfo.visitID;
      resultEntryPopup = this.OPResultEntryPreAuthPopup;
    } else {
      const inutReq = {
        sear_umr_no: labPatientInfo.patientID,
        sear_ip_no: labPatientInfo.ipNum,
        sear_hospitalid: this.userInfo.hospitalID,
      };
      ipNr = labPatientInfo.ipNum;
      this.inpatientService.patientInfo(inutReq).subscribe(
        (response) => {
          this.patientInfo = response;
          this.patientInfo['patientID'] = labPatientInfo.patientID;
      });
      //  resultEntryPopup = this.OPResultEntryPreAuthPopup;
      resultEntryPopup = this.IPResultEntryPreAuthPopup;
     
    }
    const labResultObj = {
      "patientType": patientType,
      "visitID" : visitID,
      "patientID" : labPatientInfo.patientID, 
      "ipNr" : ipNr,
      "labRequestDate": this.advSearchForm.controls.LABDATE.value,
      "serviceName": this.advSearchForm.controls.SERVICENAME.value,
      "wardName": this.advSearchForm.controls.WARDNAME.value
    }
    this.labSequenceObj.patientType = patientType;
    this.labSequenceObj.visitID = visitID;
    this.labSequenceObj.patientID = labPatientInfo.patientID;
    this.labSequenceObj.ipNr = ipNr;
    this.labService.getLabServiceResultEntry(SampleIdsService, labResultObj)
    .subscribe(response => {
      this.labSampleIds = response.sampleIds;
      this.resultIndex = 0;
      this.getSampleIdResults(this.labSampleIds[0]);
      this.OnpreAuthPopup(resultEntryPopup);
    });
  }

  public getSampleIdResults(sampleId:any){
   
    this.labSequenceObj.sampleId = sampleId;
    this.labSampleTextEditor = false;
    this.labSampleResults = [];
    this.labResultStausSequenceNo = [];
    this.labResultServices = [];
    this.subTestHeading = [];
    this.subTestServices = [];
    this.subTestInnerServices = [];
    this.html = "";
    this.selectedDoctor = "";
    this.selectedreportingDoctorID = 0;
    this.form = new FormGroup({
      editorContent: new FormControl("", Validators.required())
    });
    this.labService.getLabServiceResultEntry(SampleIdResultService, this.labSequenceObj)
    .subscribe(response => {
      this.selectedreportingDoctor = "";
      this.labSampleTextEditor = response.labTextEditorStatus;
      this.selectedDoctor = response.reportingDoctor;
      this.selectedreportingDoctorDesignation = response.reportingDoctorDesignation; 
      this.labResBtn = response.sampleResult[sampleId];
      this.labResPrintBtn = response.sampleResult['printStatus'];
      this.labAllServiceResult = response.allServices;
      //this.sortNO = this.labAllServiceResult.SERVICE_ID_59.LR_ID_13835.SORT_NR;

      // let labAllServices = Object.keys(this.labAllServiceResult);
      // console.log("Services List", this.labAllServiceResult[labAllServices[0]]);
      // let serviceResults = this.labAllServiceResult[labAllServices[0]];
      // let serviceResultKeys = Object.keys(serviceResults);
      // console.log("ServiceResult",serviceResults[serviceResultKeys[0]]);
      // let serviceResult = serviceResults[serviceResultKeys[0]];
      // console.log("Result", serviceResult.SORT_NR);     
      this.labServicePrint = response.servicePrint;
      this.departmentName = this.labServicePrint.serviceDepartment;
      this.labServiceType  = response.serviceType;
      if(this.labSampleTextEditor) {
        this.html = response.labResult.RESULT_TEXT_VALUE;
        this.form.value.editorContent = response.labResult.RESULT_TEXT_VALUE;
      } else {
        this.labServiceResult = response.labResult;
      }
      // console.log(this.labServiceResult);
      this.getReportingDoctors(this.selectedDoctor);
    });
  }
  public getDoctorSignature(selectedDoctorName) {
    const inputRequest = {
      reportDoctorName : selectedDoctorName
    }
    this.labService.getDoctorSIgnature(DoctorSignature, inputRequest)
    .subscribe(response => { 
this.doctorSignature = response.SIGNATURE_PATH
    });
  }
  public getReportingDoctors(selReportDoc:any):void{
    this.reportingDoctors = [];
    const docObj = {
      "hospitalID" : this.userInfo.hospitalID
    }
    this.selectedReportDoctor = "";
    this.labService.getReportingDoctorsInfo(ReportingDoctors, docObj)
    .subscribe(response => {
        this.reportingDoctors  = response.reportDoctors;
        this.reportingDoctors.forEach(element => {
          this.labReportingDoctors[element.reportingDoctorId] = element.reportingDoctor;
          if(element.reportingDoctor == selReportDoc) {
            this.selectedreportingDoctorID = element.reportingDoctorId; 
            this.selectedReportDoctor = element.reportingDoctor; 
            this.selectedreportingDoctorDesignation = element.reportingDoctorDesignation;
            this.getDoctorSignature(this.selectedReportDoctor);
          }
        });
    });
   
  }

}
