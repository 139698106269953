import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { Editor, Validators } from 'ngx-editor';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { LabService } from '../../lab-module/lab.service';
import { 
        radiologyoutPatientList,
        radiologyipPatientList, 
    
       } from '../../radiology-management/radiologyConstants';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { Router } from '@angular/router';
import { RadiologyService } from '../radiology.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-radiology-patient-list',
  templateUrl: './radiology-patient-list.component.html',
  styleUrls: ['./radiology-patient-list.component.css']
})
export class RadiologyPatientListComponent implements OnInit {

  @ViewChild('OPPreAuthPopup') OPPreAuthPopup;
  @ViewChild('IPPreAuthPopup') IPPreAuthPopup;
  @ViewChild('OPResultEntryPreAuthPopup') OPResultEntryPreAuthPopup;
  @ViewChild('IPResultEntryPreAuthPopup') IPResultEntryPreAuthPopup;

  @ViewChild(MatSort, { static: false }) opSort: MatSort;
  @ViewChild('opPaginator', { read: MatPaginator }) opPaginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) ipSort: MatSort;
  @ViewChild('ipPaginator', { read: MatPaginator }) ipPaginator: MatPaginator;

  opcolumnsToDisplay = ['UMRNUM','NAME','GENDERAGE','REFERREDBY', 'SERVICENAME','REQUESTDATE','REQUESTTIME','ACTION'];
  opSampleCollectioninfo: any = [];
  opSampleCollection = new MatTableDataSource(this.opSampleCollectioninfo);

  ipcolumnsToDisplay = ['UMRNUM','IPNUM','NAME','GENDERAGE','REFERREDBY','SERVICETYPE','REQUESTDATE','REQUESTTIME','ACTION'];
  ipSampleCollectioninfo: any = [];
  ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
  
  public SampleCollectionURL:any ="";
  public labServiceRequestURL:any = "";
  public bodyDisplay = 'OP';
  public serviceIndex = 0;
  public resultIndex = 0;
  public searchType = "Pending";
  public userInfo: any = {};
  public patientInfo: any = [];
  public servicesTypeList: any = {};
  public servicesList: any = {};
  public sampleServices = [];
  public servicesSequence: any = {};
  public totalCount = 0;
  public pendingCount = 0;
  public completedCount = 0;
  public resultEntryCount = 0;
  public labResultSequenceNo :any = [];
  public labResultStausSequenceNo :any = [];
  public labResultServices : any = [];
  public labResultServicesList : any = [];
  public serviceSubTestList : any = [];
  public ippendingList : any = [];
  public ipcompletedList : any = [];
  public opPendingList : any = [];
  public opCompletedList : any = [];
  public tabData = 'Pending';
  /* advanced search */
  advSearchForm: FormGroup;
  Control: AbstractControl;
  advancedSearch: any = {

  };
  loading = true;
  editor: Editor;
  html: '';
  public defaultDescription = "<h5><u><strong>Diagnosis</strong></u></h5><br /><h5><u><strong>Cross Consultations</strong></u></h5><br /><h5><u><strong>Case History</strong></u></h5><br /><h5><u><strong>Course in Hospital</strong></u></h5><br /><h5><u><strong>Investigations</strong></u></h5><br /><h5><u><strong>Treatment Given</strong></u></h5><br /><h5><u><strong>Advise At Discharge</strong></u></h5><br /><h5>Doctor Signature</h5><br /><h5>Patient/Attenders Signature</h5><br /><p>In case of emergency or to speak to your treating doctor or for appointments, please contact: 040-40108108 & 24055555 for treatment enquiries</p>";
  constructor(
    private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dataService: RadiologyService,
    private inpatientService: InpatientService,
    private router: Router,
    private toastr: ToastrService
   ) {
    this.userInfo = this.tokenService.getUser();

  }
  form = new FormGroup({
    editorContent: new FormControl("", Validators.required())
  });

  
  ngOnInit(): void {
    this.editor = new Editor();
    this.advSearchForm = this.formBuilder.group({
      URMNO : [''],
      PATIENTNAME : [''],
      VISITID : [''],
      IPNUM : ['']
    });
    this.getRadiologyPatientsList();
  }
  get doc(): AbstractControl {
    return this.form.get("editorContent").value;
  }

  public OnpreAuthPopup(collectionPopup): void {
    this.modalService.open(collectionPopup, { size: 'xl' });
  }

  public onClosePopup():void {
    this.modalService.dismissAll();
  }

  public clearSearchFields():void{
    this.advSearchForm = this.formBuilder.group({
      URMNO : [''],
      PATIENTNAME : [''],
      VISITID : [''],
      IPNUM : ['']
    });
  };

  public getData(data) {
   this.tabData = data;
   this.getRadiologyPatientsList();
  }
  /** get patients list from B-end */
  public getRadiologyPatientsList(): void {
    this.opSampleCollectioninfo = [];
    this.opPendingList = [];
    this.opCompletedList = [];
    this.ipSampleCollectioninfo = [];
    this.ippendingList = [];
    this.ipcompletedList = [];
    let inputRequest;
    if (this.bodyDisplay == "OP") {
      inputRequest = {
        "start": 0,
        "limit": 10,
        "umrNo": this.advSearchForm.controls.URMNO.value,
        "name": this.advSearchForm.controls.PATIENTNAME.value,
        "visitId": this.advSearchForm.controls.VISITID.value,
        "requestType": ""
      };
    } else {
      inputRequest = {
        "start": 0,
        "limit": 10,
        "umrNo": this.advSearchForm.controls.URMNO.value,
        "name": this.advSearchForm.controls.PATIENTNAME.value,
        "ipNr": this.advSearchForm.controls.IPNUM.value,
        "requestType": ""
      };
    }
   
    this.opSampleCollectioninfo = this.ipSampleCollectioninfo = [];

    this.SampleCollectionURL = (this.bodyDisplay == "OP") ? radiologyoutPatientList : radiologyipPatientList;

    this.dataService.getSampleCollection(this.SampleCollectionURL, inputRequest)
      .subscribe(
        (response) => {
          if (this.bodyDisplay == "OP") { 
            if(response.data){
              response.data.forEach((element) => {
                if(element.resultId == null || element.opRadiologySequenceNo == null) {
                  this.opPendingList.push(element)
                } else {
                  this.opCompletedList.push(element)
                }
              })
            }
            this.opSampleCollectioninfo = response.data
            if(this.tabData == 'All') {
              this.opSampleCollectioninfo = this.opSampleCollectioninfo;
            } else if(this.tabData == 'Pending') {
              
              this.opSampleCollectioninfo = this.opPendingList;
            } else{
              this.opSampleCollectioninfo = this.opCompletedList;
            }
            this.pendingCount = this.opPendingList.length;
            this.completedCount = this.opCompletedList.length;
            this.totalCount = response.data.length;
          } else{
            if(response.data){
              response.data.forEach((element) => {
                if(element.resultId == null || element.ipRadiologySequenceNo == null) {
                  this.ippendingList.push(element)
                } else {
                  this.ipcompletedList.push(element)
                }
              })
            }
            this.ipSampleCollectioninfo = response.data;
            if(this.tabData == 'All') {
              this.ipSampleCollectioninfo = this.ipSampleCollectioninfo;
            } else if(this.tabData == 'Pending') {
              
              this.ipSampleCollectioninfo = this.ippendingList;
            } else{
              this.ipSampleCollectioninfo = this.ipcompletedList;
            }
            this.pendingCount = this.ippendingList.length;
            this.completedCount = this.ipcompletedList.length;
            this.totalCount = response.data.length;
          }
          this.opSampleCollection = new MatTableDataSource(this.opSampleCollectioninfo);
          this.opSampleCollection.sort = this.opSort;
          this.opSampleCollection.paginator = this.opPaginator;

          this.ipSampleCollection = new MatTableDataSource(this.ipSampleCollectioninfo);
          this.ipSampleCollection.sort = this.ipSort;
          this.ipSampleCollection.paginator = this.ipPaginator;
        },
        (err) =>
        {
          this.toastr.error(err.error.message)
        },
    );
  } 

  public getResultEntryServices(labPatientInfo:any,patientType:any ):void { 
    let preAuthPopup = ''
    this.dataService.patientInfo = labPatientInfo;
    this.dataService.patientType = patientType;
    preAuthPopup = this.OPResultEntryPreAuthPopup;
    this.OnpreAuthPopup(preAuthPopup);
  }

  public requiredDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return funYear + '-' + this.checkingTwoDigits(funMonth) + '-' + this.checkingTwoDigits(funDay);
  }

  public checkingTwoDigits(info): string {
    const twoDig = info.toString().length < 2 ? '0' + info : '' + info;
    return twoDig;
  }

  public getDate() {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }

  closeModalPopup(umrNo)
  {
    this.searchType = 'Completed'
    this.tabData = 'Completed'
    this.modalService.dismissAll()
    this.advSearchForm = this.formBuilder.group({
      URMNO : umrNo,
      PATIENTNAME : [''],
      VISITID : [''],
      IPNUM : ['']
    });

    this.getRadiologyPatientsList()
  }

}

