import { Component, Input, OnInit } from '@angular/core';
import { NgxPrinterService } from 'ngx-printer';
import { SelectedBed } from '../../ward-management/discharge-summary/types';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { NursestationsService } from 'src/app/ward-management/nursestations.service';
import { ToastrService } from 'ngx-toastr';
import { Editor, Validators } from 'ngx-editor';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { AppointmentService } from 'src/app/appointments/appointment.service';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-discharge-summary-popup',
  templateUrl: './discharge-summary-popup.component.html',
  styleUrls: ['./discharge-summary-popup.component.css']
})
export class DischargeSummaryPopupComponent implements OnInit {
  public defaultDescription = '';
  @Input() selectedBed: SelectedBed;
  @Input() dischargeSummaryInfo;
  @Input() existingRecord;

  public userInfo: any = {};
  editButton = true;
  editOption = true;
  editor: Editor;
  summaryInfo: any;
  html: '';
  existingdischargeSummaryInfo: any;
  dischargeSummaryForm: FormGroup;
  public departments: any = [];
  public isDischargeSummaryExist = false;
  public isFinalizedShow = false
  dischargeTypesList: any = [  ];
  maxDate: any = ''
  minDate: any = ''
  diagnosisForm: FormGroup;
  followupForm: FormGroup;
  diagnosis: any;
  
  constructor(private printerService: NgxPrinterService,
    private tokenService: TokenStorageService,
    private nursestationsService: NursestationsService,
    private toastr: ToastrService,
    private modalService:NgbModal,
    private appointmentService: AppointmentService,
    private commonService: CommonService) {
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.getDischargeSummary();
    this.getDepartmentTypes();
    this.getDischargeType();
    this.dischargeSummaryForm = new FormGroup({
      editorContent: new FormControl('', Validators.required()),
      departmentType: new FormControl('', Validators.required()),
      dischargeType: new FormControl('', Validators.required()),
      dischargeDate: new FormControl('')
    });
    this.diagnosisForm = new FormGroup({
      provisionalDiagnosis: new FormControl('',[Validators.required()])
    });
    this.followupForm = new FormGroup({
      fdate: new FormControl('',[Validators.required()])
    });
    if (this.existingRecord == false) {
      this.editOption = false
    }
    this.maxDate = this.appointmentService.requiredDateFormat(new Date());
    this.minDate = this.commonService.dateFormate(this.selectedBed.admissionDate, 'YYYY-MM-DD');
  }

  get p(): any {
    return this.diagnosisForm.controls;
  }
  get f(): any {
    return this.followupForm.controls;
  }
  getDepartmentTypes()
  {
    this.nursestationsService.getDepartmentTypes()
      .subscribe(
        (response) =>
        {
          this.departments = response
        },
        (err) =>
        {
          console.log("Error:", err)
        }
        )
  }
  getDischargeType()
  {
    this.nursestationsService.getDischargeType()
      .subscribe(
        (response) =>
        {
          this.dischargeTypesList = response
        },
        (err) =>
        {
          console.log("Error:", err)
        }
        )
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  get doc(): AbstractControl {
    return this.dischargeSummaryForm.get("editorContent").value;
  }
  getDischargeSummary(): void {
    if (this.selectedBed) {
      const inputRequest = {
        ipNr: this.selectedBed.IpNr
      };
      this.nursestationsService.getDischargeSummaryInfo(inputRequest)
        .subscribe(
          (response: any) => {
            if(response.DISCHARGE_SUMMARY_FINALIZED === 'N')
            {
              this.isDischargeSummaryExist = true
              this.isFinalizedShow = true
            }
            this.existingdischargeSummaryInfo = response;
            this.summaryInfo = response.DISCHARGE_SUMMARY_INFO;
            this.selectedBed.address = response.ADDRESS
            this.selectedBed.refDoctorName = response.DOCTOR_NAME
            console.log("this.selectedBed.dischargeDate", this.selectedBed.dischargeDate)
            if(this.selectedBed.dischargeDate === '' || this.selectedBed.dischargeDate === null)
              this.selectedBed.dischargeDate = response.DISCHARGE_DATETIME
              
            this.dischargeSummaryForm.patchValue({
              editorContent: response.DISCHARGE_SUMMARY_INFO,
            });
          },
          (err) => {
            console.log('HTTP Error', err.error);
            this.isDischargeSummaryExist = true

            this.nursestationsService.getDefaultTemplate()
            .subscribe(
              (response: any) => {
                this.dischargeSummaryForm.patchValue({
                  editorContent: response.TEMPLATE_DESCRIPTION
                })
              },
              (err) => {
                //
              }
            )
          }
      );
    }
  }

  updateDischargeSumary(form): void {
    if (this.existingRecord == false) {
      const inputRequest = {
        ipNr: this.selectedBed?.IpNr,
        dischargeSummaryInfo: form.value.editorContent,
        headingTitle: form.value.departmentType,
        dischargeType: form.value.dischargeType,
        dischargeDate: form.value.dischargeDate,
        lastSavedBy: this.userInfo.username,
        createdUserId: this.userInfo.username,
      }
      
      this.nursestationsService.insertDischargeSummary(inputRequest)
        .subscribe(
          () => {
            this.toastr.success('Discharge summary details submited successfully..!');
            this.getDischargeSummary();
            this.editOption = true;
            this.existingRecord = true;
            this.selectedBed.dischargeType = form.value.dischargeType
            this.selectedBed.headingTitle = form.value.departmentType
          },
          (err) => {
            this.toastr.error('Something went wrong!');
            console.log('HTTP Error', err);
          }
      );
    }
    else {
      if (this.existingdischargeSummaryInfo.DISCHARGE_SUMMARY_FINALIZED !== 'Y') {
        const inputRequest = {
          ipNr: this.selectedBed?.IpNr,
          dischargeSummaryInfo: form.value.editorContent,
          headingTitle: this.existingdischargeSummaryInfo.HEADING_TITLE,
          dischargeType: this.existingdischargeSummaryInfo.DISCHARGE_TYPE,
          dischargeDate: this.existingdischargeSummaryInfo.DISCHARGE_DATETIME,
          lastSavedBy: this.userInfo.username,
          dischargeSummaryId: this.existingdischargeSummaryInfo.ID,
        };
        this.nursestationsService.updateDischargeSummary(inputRequest).subscribe(
          () => {
            this.toastr.success('Discharge summary details updated successfully!');
            this.getDischargeSummary();
            this.editOption = true;
          },
          (err) => {
            this.toastr.error('Something went wrong!');
            console.log('HTTP Error', err);
          }
        );
      }
    }
  }
  printDischargeSummary(): void {

    const shortName = this.userInfo.hospitalShortName;
    if (shortName === 'DPMTA' || shortName === 'KPMTA' || shortName === 'JPMTA') {
      // shortName === 'CPMTA' || 
      const sheet = document.createElement('style');
      sheet.innerHTML = '@page { margin-top: 2in; margin-bottom: 0.5in}';
      document.getElementById('print-discharge-summary-section').appendChild(sheet);
    } else if ( shortName !== 'CPMTA') {
      const sheet = document.createElement('style');
      sheet.innerHTML = '@page { margin-top: 2.2in; margin-bottom: 1.6in}';
      document.getElementById('print-discharge-summary-section').appendChild(sheet);
    }

    this.printerService.printDiv('print-discharge-summary-section');

  }

  finalizePopupOpen(finalizePopup): void {
    this.modalService.open(finalizePopup);
  }

  dischargeSummaryfinalizeConfirmation()
  {
    console.log("test", this.selectedBed)
    const inputReq = {
      ipNr: this.selectedBed.IpNr,
      dischargeSummaryId: this.existingdischargeSummaryInfo.ID,
      dischargeSummaryFinalize: 'Y',
    };
    // console.log(inputReq);

    this.nursestationsService
      .dischargeSummaryfinalizeConfirmation(inputReq)
      .subscribe(
        () => {
          this.toastr.success('Discharge summary details updated successfully..!');
          this.modalService.dismissAll();
          // this.router.navigate(['/nursestation']);
        },
        (err) => {
          this.toastr.error('Something went wrong!');
          console.log('HTTP Error', err);
        }
      );
  }

  onSubmitDiagnosis(){
    const input = {
      Ipno: this.selectedBed.IpNr,
      PD: "Y",
      FD: "N",
      prd: this.diagnosisForm.controls.provisionalDiagnosis.value,
      createName: this.userInfo.username,
      followupDate:""
    }
    this.nursestationsService.addProvisionalDaignosis(input).subscribe((response)=>{
    
      this.toastr.success(response.message);
      this.getProvisionalDiagnosis();
      this.diagnosisForm.reset();
    });
  }
  onSubmitFallowupDate(){
    const input = {
      Ipno: this.selectedBed.IpNr,
      PD: "N",
      FD: "Y",
      prd: '',
      createName: this.userInfo.username,
      followupDate: this.followupForm.controls.fdate.value
    }
    this.nursestationsService.addProvisionalDaignosis(input).subscribe((response)=>{
      this.toastr.success(response.message);
      this.followupForm.reset();
    });
  }

  getProvisionalDiagnosis() {
    const request = {
      Ipno: this.selectedBed.IpNr,
    }
    this.nursestationsService.getProvisionalDaignosis(request).subscribe((response)=>{
      this.diagnosis = response.PD;
    });
  }
}
