<div class="d-flex">
  <app-sidenav></app-sidenav>
  <div class="container-fluid total-page">
    <div class="row page-header">
        <div class="col-md-11 text-center">
          <h4 class="text-uppercase bold font-weight-bold orange-color">
            MRD
          </h4>
        </div>
    </div>

    <!-- Advanced search block -->
    <div class="row">
      <!-- Advance search -->
      <div class="col-12">
        <div class="p-3 mx-3">
          <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
            <div class="row advance-search py-3">
              <div class="col-sm-3 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">UMR ID</label>
                  <div class="col-8 px-0">
                    <input formControlName="UMRID" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full UMR number</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">Patient Name</label>
                  <div class="col-8 px-0">
                    <input formControlName="PATIENTNAME" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with minimum 3 characters</span>                    
                  </div>
                </div>
              </div> 
              <div class="col-sm-3 mb-3">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname">IP NR</label>
                  <div class="col-8 px-0">
                    <input formControlName="IPNR" 
                           class="form-control input-textt1 w-100" 
                           type="text" />                    
                  </div>
                </div>
              </div>                
              <div class="col-sm-2 justify-content-end">
                <button type="submit" class="btn btn-primary advanced-search-btn">Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- ./END Advance search -->

    </div>
    <!-- ./Advanced search block -->

    <div class="row justify-content-center mb-2">
      <div class="col-md-12">
        <div class="matcontent">
          <mat-table #table 
                     [dataSource]="patientsData"  
                     >
            
            <ng-container matColumnDef="URM">
              <mat-header-cell *matHeaderCellDef>
                <div>URM NO</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                <div>{{ element.P_ID }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="IPNR">
              <mat-header-cell *matHeaderCellDef>
                <div>IPNR</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                <div>{{element.IP_NR}}</div>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="PATIENTNAME">
              <mat-header-cell *matHeaderCellDef>
                <div>PATIENT NAME</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.patientName }}">
                <div>{{ element.PATIENT_NAME }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="GENDERAGE">
              <mat-header-cell *matHeaderCellDef>
                <div>Gender/Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element.GENDER}}/{{element.AGE}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DOB">
              <mat-header-cell *matHeaderCellDef>
                <div>DOB</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.DATE_OF_BIRTH }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="PARENTGUARDIAN">
              <mat-header-cell *matHeaderCellDef>
                <div>Parent/Guardian</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element.NEXT_OF_KIN_NAME}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="MOBILENO">
              <mat-header-cell *matHeaderCellDef>
                <div>Mobile no</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element.NEXT_OF_KIN_MOBILENR}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGEDATE">
              <mat-header-cell *matHeaderCellDef>
                <div>Discharge Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{element.IP_DISCHARGE_DATE}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGETYPE">
              <mat-header-cell *matHeaderCellDef>
                <div>Discharge Type</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.IP_DISCHARGE_TYPE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef>
                <div>Action</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div style="min-width: 75px;">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                    <mat-icon>more_vert</mat-icon>
                  </button> 
                  <mat-menu #menu="matMenu">
                    <button
                      (click)="uploadFile(uploadFilePopup, element)" 
                      mat-menu-item
                    ><span>Upload Documents</span>
                    </button>
                    <button
                      (click)="viewPatientDocuments(viewPatientDocumentPopup, element)" 
                      mat-menu-item
                    ><span>View Documents</span>
                    </button>
                    <button
                      (click)="finalBillprint('Full Break', element)" 
                      mat-menu-item
                    ><span>Full break</span>
                    </button>
                    <button 
                      mat-menu-item
                      (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)"
                    ><span>Discharge Summary</span>
                    </button>

                    <button 
                      mat-menu-item
                      (click)="actionIcdPop(icdPopup, element)"
                    ><span>ICD-10</span></button>

                  </mat-menu> 
                </div>
              </mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
          </mat-table>
          <div class="mat-norecords" *ngIf="patientsData.data.length === 0">No records found</div>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
            style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadFilePopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Documents</h4>
    <button type="button" class="close" aria-label="Close" (click)="makeDropZoneEmpty()">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body p-5">
    <ngx-dropzone (change)="onSelect($event)" 
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/jpeg, image/jpg, image/png, image/gif, image/tiff, video/x-msvideo, video/mp4, video/mpeg, audio/mpeg"
                  [maxFileSize]="40000000">
      <ngx-dropzone-label>Please drop the files here..!</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
    <div class="py-2">
      <p class="text-danger mb-0">
        <b>Note:</b> 
        We are allowing only .doc / .docx / .xls / .xlsx / .pdf / .png / .jpg / .JPEG / .dicom / .TIFF / .GIF / .avi / .mp4 / .mpeg / .mp3 extention files</p>
    </div>
    <div class="py-2">
      <button class="btn btn-primary" 
              type="button"
              (click)="fileUpload()">Upload</button>
    </div>
  </div>
</ng-template>

<ng-template #viewPatientDocumentPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Patient Documents</h4>
    <button type="button" 
            class="close" 
            aria-label="Close" 
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body p-3">
    
    <a [href]="mrdUrls.PATIENT_DOCS_LOCATION + document.fileName" 
       target="_blank"
       *ngFor="let document of patientDocuments, let i = index"
       class="d-block py-2">
      {{i+1}}. {{document.fileName}}
    </a>
  </div>
</ng-template>

<!-- print discharge summary popup -->
<ng-template #dischargeSummaryPrintPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Discharge Summary</h4>
    <button type="button" 
            class="close" 
            aria-label="Close" 
            (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
      <app-discharge-summary-popup
          [selectedBed]="dischargeSummaryPatientInfo" 
          [dischargeSummaryInfo]="existingDischargeSummaryInfo.DISCHARGE_SUMMARY_INFO"></app-discharge-summary-popup>
  </div>
</ng-template>
<!-- ./END print discharge summary popup -->

<!-- ICD Popup -->
<ng-template #icdPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">ICD-10 Information</h4>
    <button type="button" 
            class="close" 
            aria-label="Close" 
            (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-icd [patientDetails]="icdPatientDetails" (ICDpopupClose)="popupClose()"></app-icd>
  </div>
</ng-template>
<!-- ./ END ICD Popup -->
