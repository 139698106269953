<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">

        <div class="row page-header">
            <!-- <div class="col-1">
                <div class="info-count-box">
                    <div class="text-center info-box-number info-box-primary-text mb-0">
                        <i class="fa fa-calendar-check"></i>
                    </div>
                    <div class="text-center info-box-text">Calendar</div>
                </div>
            </div> -->

            <div class="col-md-6 text-right">
                <h4 class="text-uppercase bold font-weight-bold orange-color">Bill Summary</h4>
            </div>
            <div class="col-md-6 text-right" style="line-height: 80px;">
                <button type="button" (click)="roomInfoPopup(RoomInfoPopup)" class="btn border-btn mr-3 px-4">BedInfo</button>
                <button type="button" (click)="backServicesList()" class="btn border-btn mr-3 px-4">List of Services Charged</button>
                <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back to list</button>
            </div>

            <!-- <div class="col-1">
                <div class="info-count-box">
                    <div class="text-center info-box-number info-box-primary-text mb-0">
                        <span class="fa-stack fa-stack-adjust" data-notification-count="9">
                            <i class="fa fa-bell"></i>
                        </span>
                    </div>
                    <div class="text-center info-box-text">Todo</div>
                </div>
            </div> -->

        </div>

        <div class="row mt-4" *ngIf="finalBillStatus == 'Y'">
            <div class="col-12 m-auto">
                <span class="holdservicealert">Final bill closed for the patient</span>
            </div>
        </div>
        <div class="row mt-4" *ngIf="dischargeStatus == 'Y'">
            <div class="col-12 m-auto">
                <span class="holdservicealert">The patient was discharged</span>
            </div>
        </div>

        <!-- Patient info -->
        <div class="row justify-content-center mb-3 summary-info">
            <div class="col-10">
                <app-patient-info [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo">                    
                </app-patient-info>
            </div>
        </div>
        <!-- Patient info -->

        <div class="dropdown text-right">
            <button type="button" class="btn btn-sm border-btn mr-3 px-4 dropdown-toggle" data-toggle="dropdown" [hidden]="optionsbtn == false" 
            [disabled]="finalBillStatus == 'Y' || holdServiceInfo?.hold_service_status === 'Y' || dischargeStatus =='Y'" 
            style="background-color: #343887; width: 120px; color:white;">Options</button>
            <div id="myDropdown" class="dropdown-menu">
              <button class="btn btn-sm dropdown-item" (click)="actionRouting('admissionInformation')">Admission Information</button>
              <button class="btn btn-sm dropdown-item" (click)="actionRouting('updatePayee')">Update Payee/Sponsor Name</button>
              <button class="btn btn-sm dropdown-item" (click)="bedTransferPopUp(bedTransferPopup)">Bed Transfer</button>
              <button class="btn btn-sm dropdown-item" (click)="crossConsultantPopUp(crossConsultantPopup)">Add treating Doctor/Cross Consultant</button>
              <button class="btn btn-sm dropdown-item" (click)="actionRouting('addReferral')">Add Referral Doctor</button>
              <button class="btn btn-sm dropdown-item" (click)="actionRouting('updateExternal')">Update External Referral</button>
              <button class="btn btn-sm dropdown-item" *ngIf="rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_add_post_services" 
              (click)="actionRouting('AddInpatientServices')">Add/Post Services</button>
              
            </div>
          </div>

        <div class="row justify-content-center summary-info">
            <!-- Service type list table -->
            <div class="col-10">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <th style="width:2%;">S.No</th>
                            <th style="width:50%;">Service Type</th>
                            <th style="width:20%;">Gross Amount</th>
                            <!-- <th style="width:20%;">Discount (%)</th> -->
                            <th style="width:20%;">Net Amount</th>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="servicesList.length > 0">
                                <tr *ngFor="let service of servicesList; let i = index">
                                    <td>{{i+1}}</td>
                                    <td style="width:50%;">
                                        <a href="javascript:void(0)"
                                            (click)="billingSericePopup(billingSericePopupBlock, service)"
                                            class="underline">{{service?.Service_typename}}</a>
                                    </td>
                                    <td style="width:20%;">{{service?.totalAmount}}</td>
                                    
                                    <td style="width:30%;" *ngIf="false">
                                        <ng-container *ngIf="service?.Discount != '--'">
                                            {{service?.Discount}}
                                        </ng-container>                                        
                                        
                                        <!-- hiding service category discount functionality -->
                                        <!-- By Yuva on 08-08-2021 -->
                                        <ng-container *ngIf="false">

                                            <ng-container *ngIf="finalBillStatus == 'N' && service?.Discount == '--' && !service?.discount_status">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input type="text" [value]="service.DiscountAmt" (keyup)="changeDiscountAmount($event, service)" (blur)="blurDiscountAmount($event, service)" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" [value]="service.DiscountPer" (keyup)="changeDiscount($event, service)" (blur)="blurDiscount($event, service)" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                                        <span>(%)</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <button type="button" (click)="applyDiscount(service)" class="btn btn-sm brand-btn">Save</button>
                                                    </div>
                                                </div>
                                            </ng-container>
    
                                            <ng-container *ngIf="finalBillStatus == 'N' && service?.discount_status">
                                                <ng-container *ngIf="service?.discount_status.DISCOUNT_STATUS">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <input type="text" [value]="service.DiscountAmt" (keyup)="changeDiscountAmount($event, service)" (blur)="blurDiscountAmount($event, service)" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <input type="text" [value]="service.DiscountPer" (keyup)="changeDiscount($event, service)" (blur)="blurDiscount($event, service)" appOnlyNumberdecimal numericType="number" class="form-control form-control-sm" />
                                                            <span>(%)</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <button type="button" (click)="applyDiscount(service)" class="btn btn-sm brand-btn">Save</button>
                                                        </div>
                                                    </div>
                                                </ng-container>                                            
                                            </ng-container>

                                        </ng-container>
                                        <!-- hiding service category discount functionality -->
                                        <!-- By Yuva on 08-08-2021 -->                                        

                                    </td>

                                    <!-- <td>{{service?.netAmount}}</td> -->
                                    <td style="width:20%;">{{service?.totalAmount}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="servicesList.length == 0">
                                <tr>
                                    <td colspan="5">No services found</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- ./END Service type list table  -->
            <!-- Discount apply -->
            <div class="col-10">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <ng-container *ngIf="finalBillStatus == 'N' && billingSummaryDetails?.Discount == '0.00' && holdServiceInfo?.hold_service_status == 'Y'">
                            <div class="row bill-summ-row">
                                <div class="col-md-12"><label>Apply Discount</label></div>
                            </div>
                            <div class="row bill-summ-row">
                                <div class="col-md-5"><label>Discount Amount</label></div>
                                <div class="col-md-5">
                                    <input type="text" [value]="discountForm.discountAmount" [disabled]="disableDiscountAmount" (focus)="focusDiscountAmount()" (keyup)="changeDiscountAmount($event, discountForm)" (blur)="blurDiscountAmount($event, discountForm)" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                </div>
                            </div>
                            <div class="row bill-summ-row">
                                <div class="col-md-5"><label>Discount (%)</label></div>
                                <div class="col-md-5">
                                    <input type="text" [value]="discountForm.discountPercentage" [disabled]="disableDiscountPercentage" (focus)="focusDiscountPercentage()" (keyup)="changeDiscount($event, discountForm)" (blur)="blurDiscount($event, discountForm)" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                </div>
                            </div>
                            <div class="row bill-summ-row">
                                <div class="col-md-5"><label>Net Amount will be</label></div>
                                <div class="col-md-5">
                                    {{ discountForm.netAmount }}
                                </div>
                            </div>
                            <div class="row bill-summ-row">
                                <div class="col-md-5">&nbsp;</div>
                                <div class="col-md-5">
                                    <button type="button" (click)="applyDiscount(discountForm)" class="btn btn-sm brand-btn">Save</button>
                                    &nbsp;
                                    &nbsp;
                                    <button type="button" (click)="resetDiscount()" class="btn btn-sm brand-btn">Reset</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-12 col-md-6">
                        <ng-container>
                            <ng-container *ngIf="finalBillStatus == 'N' && discountStatus == 'PENDING'">
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <label>Discount Approval</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label>
                                            <input type="checkbox" [checked]="discountApprove == 'Y'" (change)="changeDiscountApprove($event)" />
                                            Approve
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <label>
                                            <input type="checkbox" [checked]="discountReject == 'Y'" (change)="changeDiscountReject($event)" />
                                            Reject
                                        </label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5" *ngIf="discountApprove == 'Y'">
                                        <label>Discount Authorized By</label>
                                    </div>
                                    <div class="col-md-5" *ngIf="discountApprove == 'Y'">
                                        <select [(ngModel)]="selectedAuthorizationName" class="form-control input-sm">
                                            <option value="">Select</option>
                                            <option *ngFor="let an of authorizationNames" value="{{ an.DAN_NAME }}">
                                              {{an.DAN_NAME}}
                                            </option>                 
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <button type="button" [disabled]=" (discountReject == 'N' && discountApprove == 'N') || (discountApprove == 'Y' && selectedAuthorizationName == '')  " (click)="saveDiscountAssign()" class="btn btn-sm brand-btn">Save</button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- Discount apply -->

            <!-- billing information -->
            <div class="col-10 mt-5">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails.Gross_Amount > 0">
                            <div class="col-md-5"><label>{{billingSummaryDetails?.Gross_Amount_label}}</label></div><!-- Total Bill Amount -->
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.Gross_Amount}}</span><!-- billingSummaryDetails?.Total_Bill_Amount -->
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails?.Discount > 0">
                            <div class="col-md-5"><label>Discount Amount</label></div><!-- Amount Paid -->
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.Discount}}</span><!--billingSummaryDetails?.Amount_Paid -->
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails?.ServiceBasedDiscount > 0">
                            <div class="col-md-5"><label>Service based Discount Amount</label></div><!-- Amount Paid -->
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.ServiceBasedDiscount}}</span><!--billingSummaryDetails?.Amount_Paid -->
                                <b>(Round off: {{roundoffAmount}})</b>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                            <div class="col-md-5"><label>Discount Approved By</label></div><!-- Discount -->
                            <div class="col-md-7">
                                <span>({{billingSummaryDetails?.discountApprovedBy}})</span><!-- billingSummaryDetails?.Discount -->
                            </div>
                        </div>
                        <div class="row bill-summ-row">
                            <div class="col-md-5">
                                <label>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</label></div><!-- billingSummaryDetails?.Balance_label -->
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.NetAmount}}</span><!-- billingSummaryDetails?.Balance_Amount -->
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
                            <div class="col-md-5"><label>{{billingSummaryDetails?.Receipt_Amount_Label}}</label></div>
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.Receipt_Amount}}</span>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
                            <div class="col-md-5"><label>{{billingSummaryDetails?.Balance_label}}</label></div>
                            <div class="col-md-7">
                                <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
                            <div class="col-md-5"><label>{{billingSummaryDetails?.AmountRefundedLabel}}</label></div>
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.AmountRefundedAmount}}</span>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
                            <div class="col-md-5"><label>{{billingSummaryDetails?.Refund_payble_Amount_label}}</label></div>
                            <div class="col-md-7">
                                <span>{{billingSummaryDetails?.Refund_payble_Balance_amount}}</span>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="false && holdServiceInfo?.hold_service_status == 'Y' && finalBillStatus == 'N'">
                            <div class="col-md-5"><label>Round off Amount</label></div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-3">
                                        <button type="button" (click)="roundOffAdjust('minus')" [disabled]="roundoffAmount == -2" class="btn btn-sm border-btn mr-3"><b>-</b></button>
                                    </div>
                                    <div class="col-md-6 p-0" style="text-align: center;">
                                        <input type="text" readonly [value]="paybleRoundoffAmount" appOnlyNumberdecimal numericType="decimal2digits" class="form-control form-control-sm" />
                                        <b>(Round off: {{roundoffAmount}})</b>
                                    </div>
                                    <div class="col-md-3">
                                        <button type="button" (click)="roundOffAdjust('plus')" [disabled]="roundoffAmount == 2" class="btn btn-sm border-btn mr-3"><b>+</b></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row bill-summ-row" *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_hold_services_btn">
                            <div class="col-md-5"><label>Hold Service</label></div>
                            <div class="col-md-7">
                                <input type="checkbox" [disabled]="holdServiceInfo?.hold_service_status === 'Y'"
                                    [checked]="holdServiceInfo?.hold_service_status === 'Y'"
                                    (change)="holdService($event, holdServiceInfo?.hold_service_status)" />

                                <ng-container *ngIf="finalBillStatus === 'N'">
                                    <a href="javascript:void(0)" class="ml-2 underline"
                                        *ngIf="holdServiceInfo?.hold_service_status === 'Y'"
                                        (click)="holdServiceUndo()">Undo</a>
                                    <span *ngIf="holdServiceInfo?.hold_service_status === 'Y'">
                                        ({{holdServiceInfo?.message}})
                                    </span>
                                </ng-container>

                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-md-6 mb-3">

                        <ng-container>
                            <form (ngSubmit)="addRemark.form.valid && submitRemar()" #addRemark="ngForm" autocomplete="off">
                                <div class="row mb-3">
                                    <div class="col-12 d-flex align-items-center">
                                        <label>Remarks <span style="color: #ccc;">(at least 10 characters)</span></label>
                                    </div>
                                    <div class="col-12">
                                        <textarea class="form-control" [(ngModel)]="remarkFormData.remark" name="remark"
                                            #remark="ngModel" required></textarea>
                                        <!-- <span *ngIf="addRemark.submitted && remarkFormData.remark === ''"
                                            class="bg-warningtxt">This field is required.</span> -->
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <label>
                                            <input type="checkbox" [checked]="remarkFormData.display_in_print == 'Y'" (change)="changeDisplayInPrintout($event)" />
                                            Display in Printout
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="submit" [disabled]="dischargeStatus == 'Y' || remarkFormData.remark == ''" class="btn btn-sm brand-btn">Submit
                                            Remarks</button>
                                    </div>
                                </div>
                                
                            </form>
                        </ng-container>

                        <ng-container>
                            <form (ngSubmit)="addPatientRemarks.form.valid && submitPatientRemar()" #addPatientRemarks="ngForm" autocomplete="off">
                                <div class="row mb-3">
                                    <div class="col-12 d-flex align-items-center">
                                        <label>Patient Remarks <span style="color: #ccc;">(at least 10 characters)</span></label>
                                    </div>
                                    <div class="col-12">
                                        <textarea class="form-control" [(ngModel)]="patientRemarkFormData.remark" name="remark"
                                            #remark="ngModel" required></textarea>
                                    </div>
                                </div>
                                <button type="submit" [disabled]="dischargeStatus == 'Y' || patientRemarkFormData.remark == ''" class="btn brand-btn">Submit
                                    Remarks</button>
                            </form>
                        </ng-container>

                    </div>
                </div>
            </div>
            <!-- ./END billing information -->
        </div>

        <div class="row justify-content-center summary-actions py-4 summary-info">
            <!--OLD ONE <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="viewBillPopUp(viewBill)"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_summary_bill_btn"
                    >View Bill</button> -->
            <!-- <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="finalBillprint('Full Break')"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_summary_bill_btn"
                    >View Bill</button> -->
            <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="finalBillprint('Summary')"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_summary_bill_btn"
                    >Summary Bill</button>
            <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="finalBillprint('Full Break')"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_full_break_btn"
                    >Full Break</button>
            <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="finalBillprint('Day Wise Break')"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_day_wise_break_btn"
                    >Day Wise Break</button>
            <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    *ngIf="holdServiceInfo?.hold_service_status !== 'Y' && rolesInfo.attributeLevel.ward_bed_management_bill_summary_add_receipts_btn"
                    (click)="receiptPopup(paymentReceipt)">Add Receipts</button>
            <button type="button" 
                    class="btn border-btn mr-3 px-4" 
                    (click)="viewReceipts()"
                    *ngIf="rolesInfo.attributeLevel.ward_bed_management_bill_summary_view_receipts_btn"
                    >View Receipts</button>

            <button type="button" 
                    [disabled]="finalBillStatus == 'Y'" 
                    class="btn border-btn mr-3 px-4"
                    *ngIf="holdServiceInfo?.hold_service_status == 'Y' && rolesInfo.attributeLevel.ward_bed_management_bill_summary_finalize_bill_btn" 
                    (click)="finalizeBillPopup(finalizeBill)">
                Finalize Bill
            </button>

            <button type="button" 
                    [disabled]="dischargeStatus == 'Y'" 
                    class="btn border-btn mr-3 px-4" 
                    *ngIf="finalBillStatus == 'Y' && rolesInfo.attributeLevel.ward_bed_management_bill_summary_discharge_btn"
                    (click)="DischargePopup(dischargePopup)">
                Discharge
            </button>

            <div class="dropdown text-right">
                <button type="button" class="btn btn-sm border-btn mr-3 px-4 dropdown-toggle" data-toggle="dropdown" 
                [hidden]="payeeName == 'SELFPAY'"style="width: 120px; height:35px;">Insurance</button>
                <div id="myDropdown" class="dropdown-menu">
                  <button [hidden]="finalBillStatus == 'Y'" class="btn btn-sm dropdown-item" (click)="interimmBillPopup(interimBillPopup,'interimBill')">Interim Bill</button>
                  <button class="btn btn-sm dropdown-item" (click)="finallBillPopup(finalBillPopup, 'finalBill')">Final Bill</button>
                </div>
            </div>

            <!-- <button type="button" [hidden]="finalBillStatus == 'Y'" [hidden]="payeeName == 'SELFPAY' " 
                    class="btn border-btn mr-3 px-4"                    
                    (click)="interimmBillPopup(interimBillPopup,'interimBill')">
                    Interim Bill 
            </button>

            <button type="button" [hidden]="payeeName == 'SELFPAY' " 
                    class="btn border-btn mr-3 px-4" 
                    (click)="finallBillPopup(finalBillPopup, 'finalBill')">
                Final Bill
            </button> -->

        </div>
        <!-- Remarks table -->
        <div *ngIf="remarksList.length !== 0" class="row justify-content-center summary-actions my-4">
            <div class="col-10">
                <h4>Remarks List</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <th style="width:2%;">S.no</th>
                            <th style="width:15%;">Remarks</th>
                            <th style="width:15%;">Created By Name</th>
                            <th style="width:20%;">Created Time</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let remark of remarksList; let i = index">
                                <td style="width:2%;">
                                    {{i+1}}
                                </td>
                                <td style="width:15%;">{{remark.REMARKS}}</td>
                                <td style="width:15%;">{{remark.CREATED_ID}}</td>
                                <td style="width:20%;">{{remark.CREATED_DATE_TIME}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- billing services popup -->
    <ng-template #billingSericePopupBlock let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Service(s) List</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="line-heading">
                <div class="block lg p-1">
                    {{sericePopupInfo.title}}
                </div>
                <hr />
            </div>
            <div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Service Name</th>
                            <th>Unit QTY</th>
                            <th>Cost per Unit</th>
                            <th>Net Amount</th>
                            <!-- <th>Discount (%)</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let service of serviceGroupList; let i = index ">
                            <td scope="row">{{i+1}}</td>
                            <td>{{service?.SERVICE_NAME}}</td>
                            <td>{{service?.SERVICE_UNIT_QTY}}</td>
                            <td>{{service?.SERVICE_AMOUNT}}</td>
                            <td>{{service?.SERVICE_AMOUNT}}</td>
                            <!-- <td>{{service?.SERVICE_NET_AMOUNT}}</td> -->
                            <!-- <td>{{service?.SERVICE_DISCOUNT_AMOUNT + '(' + service.SERVICE_DISCOUNT_PERCENTAGE+ ')'}}
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-template>
    <!-- ./END billing services popup -->

    <!-- Start Pre-Auth popup -->
    <ng-template #preAuthPopup let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Pre-Auth Form</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <app-pre-auth [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo"
                (closePopup)='closeModalPopup($event)'></app-pre-auth>
        </div>
    </ng-template>
    <!-- End Pre-Auth popup -->

    <!-- Payment process popup -->
    <ng-template #paymentReceipt let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Payment Process</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <app-payment-process [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo"
                (closePopup)='closeModalPopup($event)'></app-payment-process>
        </div>
    </ng-template>
    <!-- End Payment process popup -->
     <!-- View Bill popup -->
     <ng-template #viewBill let-modal>
        <div class="modal-header">
            <h4 class="modal-title">View Bill</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <app-view-bill
                (closePopup)='closeModalPopup($event)'></app-view-bill>
        </div>
    </ng-template>
    <!-- End View Bill popup -->

    <ng-template #dischargePopup let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Discharge Form</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <fieldset class="legend-block">
                    <legend class="legend-title mb-0 font-16">Patient Info</legend>
                    <div class="row">
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Patient Name </div>
                                <div class="col">: {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> IP No </div>
                                <div class="col">: {{patientInfo?.IP_NR}} </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
                                <div class="col">: {{patientInfo?.WM_WARD_NAME}}
                                    <span>({{patientInfo?.WM_BED_NO}})</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Gender/Age </div>
                                <div class="col">: {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Admitting Doctor </div>
                                <div class="col">: {{patientInfo?.DM_NAME}} </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Payee Name </div>
                                <div class="col">: {{patientInfo?.PIM_NAME}} </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pt-3">
                            <div class="row">
                                <div class="col-5 font-weight-bold"> Referred By </div>
                                <div class="col">: {{patientInfo?.REFERRED_BY}} </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="mt-2" *ngFor="let value of DischargeFormRadioButtons">
                    <div>
                        <input (change)="selectedradioButton(value)" type="radio" [value]="value.id" name="payment" />
                        <label class="paymentType pl-2" [for]="value.id">{{value.name}}</label>
                    </div>
                </div>
                <button type="button" [disabled]="selectedValue == ''"
                    (click)="submitDischargeForm(); modal.dismiss('Cross click')"
                    class="btn btn-primary float-right">Submit</button>
            </div>
        </div>
    </ng-template>
    <!-- ./ END Patient receipt popup -->

    

    <ng-template #finalizeBill let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Finalize Bill</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 col-sm-12 margin45">
                    <p>{{billingSummaryDetails?.Balance_label}} : {{billingSummaryDetails?.Balance_Amount}}</p>
                </div>
            </div>            
            <div class="row">
                <div class="col-12 col-lg-7 mt-3">
                    <fieldset class="legend-block">
                        <legend class="legend-title mb-0 font-16">Payment Selection</legend>

                        <p>Please select the mode of payment to collect advance/Deposit for the patient:</p>

                        <ng-container *ngFor="let paymentMode of paymentModeList">

                            <div class="mt-2" *ngIf="paymentMode.paymentType == 1">

                                <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                    (ngModelChange)="clearAmount(paymentMode)"
                                    (change)="changePaymentMode($event, paymentMode)">
                                <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>

                                <input *ngIf="paymentMode.selectedPaymentType" [(ngModel)]="paymentMode.collectedAmount"
                                    type="text"
                                    class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="decimal2digits" />

                            </div>

                            <div class="mt-2" *ngIf="paymentMode.paymentType == 3">

                                <span
                                    *ngIf="billingSummaryDetails?.Balance_Amount===0 || billingSummaryDetails?.Balance_Amount > 0">
                                    <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                        (ngModelChange)="clearAmount(paymentMode)"
                                        (change)="changePaymentMode($event, paymentMode)">
                                    <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>
                                </span>

                            </div>

                            <div class="mt-2" *ngIf="paymentMode.paymentType == 2">

                                <span *ngIf="billingSummaryDetails?.Balance_Amount < 0">
                                    <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                        (ngModelChange)="clearAmount(paymentMode)"
                                        (change)="changePaymentMode($event, paymentMode)">
                                    <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>

                                    <input *ngIf="paymentMode.selectedPaymentType" [(ngModel)]="paymentMode.collectedAmount"
                                    type="text"
                                    class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="decimal2digits" readonly />

                                </span>

                            </div>

                        </ng-container>


                        <!-- <div class="mt-2"
                            *ngIf="billingSummaryDetails?.Balance_Amount===0 || billingSummaryDetails?.Balance_Amount > 0">

                            <input type="checkbox" [disabled]="modeCreditVoucher.isDisable"
                                [(ngModel)]="modeCreditVoucher.selectedPaymentType" name="payment"
                                (ngModelChange)="clearAmount(modeCreditVoucher)" (change)="changeCreditVoucher($event)">
                            <label class="paymentType pl-2">{{modeCreditVoucher.paymentModeName}}</label>

                        </div>

                        <div class="mt-2" *ngIf="billingSummaryDetails?.Balance_Amount < 0">

                            <input type="checkbox" [disabled]="modeRefundLater.isDisable"
                                [(ngModel)]="modeRefundLater.selectedPaymentType" name="payment"
                                (ngModelChange)="clearAmount(modeRefundLater)" (change)="changeRefundLater($event)">
                            <label class="paymentType pl-2">{{modeRefundLater.paymentModeName}}</label>

                        </div> -->

                    </fieldset>
                </div>

                <div class="col-12 col-lg-5 mt-3">
                    <fieldset class="legend-block" *ngIf="isPaymentSelected()">
                        <legend class="legend-title mb-0 font-16">Transaction details</legend>
                        <div class="form-group twoInputssameLine mx-2 mb-2">
                            <label for="transactionId" class="text-dark mb-1">
                                Transaction ID
                                <span class="requiredSymbol">*</span>
                            </label>
                            <input type="text" class="form-control form-control-sm" id="transactionId"
                                [(ngModel)]="transactionId" />
                        </div>
                        <div class="form-group twoInputssameLine mx-2 mb-2">
                            <label for="bankname" class="text-dark mb-1">
                                Bank Name
                                <span class="requiredSymbol">*</span>
                            </label>
                            <input type="text" class="form-control form-control-sm" id="bankname"
                                [(ngModel)]="bankName" />
                        </div>
                        <div class="form-group twoInputssameLine mx-2 mb-2">
                            <label for="mobilee" class="text-dark mb-1">
                                Mobile
                                <span class="requiredSymbol">*</span>
                            </label>
                            <input type="text" class="form-control form-control-sm" id="mobilee"
                                [(ngModel)]="mobileNumber" maxlength="10" minlength="10" appOnlyNumberdecimal numericType="number" />
                        </div>
                        <div *ngIf="isPaymentChequeSelected()" class="form-group twoInputssameLine mx-2 mb-2">
                            <label for="issuedate" class="text-dark mb-1">
                                Issue Date
                                <span class="requiredSymbol">*</span>
                            </label>
                            <input type="date" class="form-control form-control-sm" id="issuedate"
                                [(ngModel)]="issueDate" (keypress)="numberOnlyVal($event, 'data')" />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="row">
                <div class="col-12">&nbsp;</div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="button" [disabled]="checkCollectAmount()" class="btn btn-primary"
                        (click)="saveFinalizeBill()">
                        Submit
                    </button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-primary cancelBtn" (click)="finalizeBillPopupClose()">
                        Cancel
                    </button>
                </div>
            </div>

        </div>
    </ng-template>

    <ng-template #ipAdmissionInformationPopup let-modal>
        <div class="modal-header">
          <h4 class="modal-title">Ip Admission Information</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-ipadmission-info (popupClose)="popUpClose()" [patientInfo]="patientInfo"></app-ipadmission-info>
        </div>
    </ng-template>

    <ng-template #updatePayeeSponsorPopup let-modal>
        <div class="modal-header">
          <h5 class="modal-title">Update Payee/Sponsor Name</h5>
          <button type="button" class="close" aria-label="close" (click)="modal.dismiss('Corss click')">
            <span aria-hidden="true">X</span>
          </button>
        </div>
        <div class="modal-body">
          <app-update-payee (popupClose)="popUpClose()" [ipPatientNO]="ipPatientNO"></app-update-payee>
        </div>
    </ng-template>

    <!-- bed transfer popup -->
    <ng-template #bedTransferPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Bed Transfer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="transferForm.form.valid && submitTransferFormDetails()" #transferForm="ngForm">
        <div class="form-group">
          <label class="font-weight-bold">Select Ward: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" 
                  name="wardList" 
                  [(ngModel)]="transferFormData.wardListId"
                  #wardList="ngModel" 
                  id="wardList" 
                  (change)="changeWard(transferFormData.wardListId)" 
                  required>
            <option value="">-- Select Ward --</option>
            <option *ngFor="let ward of completeWardsList; let i = index" [ngValue]="ward?.WM_WARD_M_ID">
              {{ward?.WM_WARD_NAME}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && wardList.invalid" class="bg-warningtxt">
            Ward is required
          </span>
        </div>

        <div class="form-group">
          <label class="font-weight-bold">Select Bed: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" name="patientBed" [(ngModel)]="transferFormData.patientBedId"
            #patientBed="ngModel" id="patientBed" required>
            <option value="">-- Select Bed --</option>
            <option *ngFor="let bed of bedsList; let i = index" [ngValue]="bed?.WM_BED_M_ID">
              {{bed?.WM_BED_NO}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && patientBed.invalid" class="bg-warningtxt">
            Bed is required
          </span>
        </div>
        <span class="text-danger">{{transferBedError}}</span>
        <div class="float-left mr-2" *ngIf="bedTransferSubmitBtn">
          <button class="btn btn-primary">Submit</button>
        </div>
        <div *ngIf="!bedTransferSubmitBtn">
          <div>Are you sure really transfer the bed?</div>
          <button class="btn btn-success mr-2">Transfer Bed</button>
          <button class="btn btn-danger mr-2" type="button" (click)="closeBedTransferPopup()">Cancel</button>
        </div>
      </form>
    </div>
    </ng-template>
    <!-- ./END bed transfer popup -->
    <!-- Cross Consultant -->
    <ng-template #crossConsultantPopup let-modal class="consult-form">
    <div class="modal-header">
      <h4 class="modal-title">Add Treating Doctor / Cross Consultant</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <form  #consultantForm="ngForm">
      <div class="row">
        <div class="col-md-6">
          <div class="row form-group">
            <div class="col-md-4">
              <label for="docname" class="font-weight-bold">Doctor Name: <span
                class="requiredSymbol">*</span></label>
            </div>
            <div class="col-md-8">
              <ng-autocomplete #doctorauto
                  [data]="filterDoctorList"
                  [searchKeyword]="doctorNameFilterKeyword"
                  placeholder="Enter the Doctor's Name"
                  (selected)='selectAutocompleteEvent($event, "doctorname")'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  class="autocomplete-dropdown bb"
                >
                </ng-autocomplete>          
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.doctorName"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
                <span *ngIf="consultantForm.submitted && consultantFormData.doctorId == '' " class="bg-warningtxt">Doctor Name is
                  required.</span>
            </div>
          </div>        
        </div>
      
        <div class="col-md-6">
          <div class="row form-group">
            <div class="col-md-4">
              <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
            </div>
            <div class="col-md-8">
              <select class="form-control ropdoun bb" 
              name="dptName" 
              [(ngModel)]="consultantFormData.departmentId"
              #dpartName="ngModel" 
              [ngClass]="{ 'is-invalid-error': consultantForm.submitted && dpartName.invalid }"
              (change)="departmentChange(consultantFormData.departmentId)" 
              id="dptName"                            
              required>
              <option value="">-- Select Department --</option>
              <option *ngFor="let deprtment of departmentList; let i = index" [ngValue]="deprtment.deptId">
              {{deprtment.deptName}}
              </option>
              </select>
              <span *ngIf="consultantForm.submitted && dpartName.invalid" class="bg-warningtxt">Department is
              required</span>
            </div>
          </div>
        </div>
      </div><br>
      <div class="text-center">
        <button class="btn btn-primary mr-2 " (click)="submitConsultantFormDetails()"
      >Submit</button>
      <div class="float-left mr-2" *ngIf="consultFormErrorMessage"></div>
      </div>  
     </form>       
    </div>
    </ng-template>
    <!-- end Cross Consultant -->

     <!-- External Referral -->
    <ng-template #externalReferralPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Update External Referral</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
     <app-update-external (popupClose)="popUpClose()" [ipPatientNO]="ipPatientNO"></app-update-external>       
    </div>
    </ng-template>
    <!--End External Referral -->

    <!-- Add Referral Docotr -->
  <ng-template #addReferralPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Referral Doctor</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-referral (popupClose)="popUpClose()"></app-add-referral>
    </div>
  </ng-template>
  <!-- Add Referral Doctor -->

    <ng-template #interimBillPopup let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Interim Bill</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Patient information -->
            <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        Patient Name
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        DOA
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Consultant
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Consultant}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <b>UMR No</b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Department
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Admitted Ward
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Address
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        <b>Admission No </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Relation
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Relation}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Age /Sex
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AgeSex}}
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-5">
                        TPA Company
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.mainPayeeName}}
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-5">
                        <b>Main Payee Name </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PayeeName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Referral
                    </div>
                    <div class="col-md-7">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{patientInfoList.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{patientInfoList.Referral}}({{patientInfoList.ReferralCity}})
                        </div>                
                    </div>
            </div>
            </div>
            </div>
            <!-- ./END Patient information -->
            <div class="mt-2 text-center border border-dark p-2"  style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesTo}}</span>
            </b>
            </div>
            <ng-container>
                <div>
                    <div class="row table1 font-weight-bold" style="font-size: 16px;">
                        <div class="col-12">
                            <hr class="my-2 borer border-dark" />
                        </div>
                        <div class="col-md-2">
                            Service Code
                        </div>
                        <div class="col-md-3">
                            Services/Investigation
                        </div>
                        <div class="col-md-2">
                            Qty.
                        </div>
                        <div class="col-md-2 text-right" style="padding-right: 25px;">
                            Rate
                        </div>
                        <div class="col-md-2 text-center">
                            <span>Discount</span>
                        </div>
                        <div class="col-md-1 text-right">
                            Amount
                        </div>
                        <div class="col-12">
                            <hr class="my-2 pdf-thick-border" />
                        </div>
                    </div>
                </div> 
                    <ng-container>
                        <div *ngFor="let service of finalBillServicesList;">
                            <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                                <div class="col-md-2">
                                    {{subService?.ServiceCode}}
                                </div>
                                <div class="col-md-3">
                                    {{subService?.Services}}
                                </div>
                                <div class="col-md-1">
                                    {{subService?.Qty}}
                                </div>
                                <div class="col-md-1 text-right">
                                    *
                                </div>
                                <div class="col-md-2 text-right">
                                    <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                                </div>
                                <div class="col-md-2 text-center">
                                    <span>{{subService?.Discount}}</span>
                                </div>
                                <div class="col-md-1 text-right">
                                    <span class="tablecol">{{ subService?.Amount}}</span>
                                </div>
                            </div>
                        </div>      
                    </ng-container>
            </ng-container>
            
            <br />
            <hr class="my-2 border border-dark" />
            
            <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
            </div>
            <div class="row" style="font-size: 16px;" >
            <div class="offset-6 col-3 text-right">
                <b>Approved Amount</b>
            </div>
            <div class="col-3 text-right">
                <b>{{insuranceList.approvalAmount}}</b>
            </div>
            </div>
            
            <div class="row" style="font-size: 16px;" >
                <div class="offset-6 col-3 text-right">
                    <b>Balane Amount</b>
                </div>
                <div class="col-3 text-right">
                    <b>{{balance_amount}}</b>
                </div>
            </div>
            <br />
            
            <div>
            <div>
                <b style="font-size: 17px;">Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1" style="font-size: 17px;">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Credit Amt</b></div>
                <div class="col-md-2"><b>Claim No</b></div>
                <div class="col-md-2"><b>Approval Amt</b></div>
                <div class="col-md-2"><b>Interim Bill</b></div>
                
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;">
                <div class="col-md-1">{{insuranceList.RecptNo}}</div>
                <div class="col-md-2">{{insuranceList.RecptDt}}</div>
                <div class="col-md-2">{{billingSummaryDetails?.Gross_Amount}}</div>
                <div class="col-md-2">{{insuranceList.claimNumber}}</div>
                <div class="col-md-2">{{insuranceList.approvalAmount}}</div>
                <div class="col-md-2">{{insuranceList.InterimBill}}</div>
            </div>
            <hr class="my-2 border border-dark" />
            <!-- <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div> -->
            </div>
            
            <hr class="my-2 border border-dark" />
            
            <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
            </div>

            <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}} </b>
            </div>

            <div class="row" style="font-size: 16px;">
                <div class="col-6 text-left">
                    <div class="row mt-5 mb-3">
                        <div class="col-4"><b>Admission Created By</b></div>
                        <div class="col">{{patientInfoList.admissionCreatedBy}}</div>
                    </div>
                </div>
                <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
                </div>
            </div>
            
            <!-- <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div> -->
            <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt}}
                    </div>
                </div>
            </div>
            </div>
            <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn}}
                    </div>
                </div>
            </div>
            </div>
            
            <!-- <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
            
            </div>
            
            <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
            
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                    </div>
                </div>
            </div>
            </div> -->
        </div>
        <div class="text-center">
            <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="interimBillPopupClose()" type="submit" value="Close">Close</button>
            <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="intermBillPrint()" type="submit" value="Print">Print</button>
        </div>        
    </ng-template>
    <ng-template #finalBillPopup let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Final Bill</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- Patient information -->
            <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            Patient Name
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.PatientName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            DOA
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.DateOfAdmission}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Consultant
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.Consultant}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <b>UMR No</b>
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.UMRNo}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Department
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.Department}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Admitted Ward
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.AdmittedWard}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Discharge Date
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{insuranceList.dischargeDate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Address
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.Address}}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-5">
                            <b>Admission No </b>
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.AdmissionNo}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            S-W-D-B/O
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.SWDBO}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Relation
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.Relation}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            Age /Sex
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.AgeSex}}
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-5">
                            TPA Company
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.mainPayeeName}}
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-md-5">
                            <b>Main Payee Name </b>
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.PayeeName}}
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-md-5">
                            <b>Bill No </b>
                        </div>
                        <div class="col-md-7">
                            <b>:</b> {{patientInfoList.BillNo}}
                        </div>
                    </div>
                    <div class="row" > 
                        <div class="col-md-5">
                            Referral
                        </div>
                        <div class="col-md-7">
                            <div *ngIf="walkin == true">
                                <b>:</b> {{patientInfoList.Referral}}
                            </div>
                            <div *ngIf="walkin == false">
                                <b>:</b> {{patientInfoList.Referral}}({{patientInfoList.ReferralCity}})
                            </div>                
                        </div>
                </div>
                </div>
            </div>
            <!-- ./END Patient information -->
            <div class="mt-2 text-center border border-dark p-2"  style="font-size: 17px;">
                <b>Hospitalisation Charges from
                    <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesFrom}}</span>
                    <span class="pl-2">To</span>
                    <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesTo}}</span>
                </b>
            </div>
            <ng-container>
                <div>
                    <div class="row table1 font-weight-bold" style="font-size: 16px;">
                        <div class="col-12">
                            <hr class="my-2 borer border-dark" />
                        </div>
                        <div class="col-md-2">
                            Service Category
                        </div>
                        <div class="col-md-4">
                            Services/Investigation
                        </div>
                        <div class="col-md-2">
                            Qty.
                        </div>
                        <div class="col-md-2 text-right" style="padding-right: 25px;">
                            Rate
                        </div>
                        <div class="col-md-2 text-right">
                            Amount
                        </div>
                        <div class="col-12">
                            <hr class="my-2 pdf-thick-border" />
                        </div>
                    </div>
                </div>                 
                    <ng-container>
                        <div *ngFor="let service of finalBillServicesList;">
                            <div class="row table2" style="font-size: 17px;">            
                                <div class="col-md-6 ">
                                    <b>{{service?.ServiceType}}</b>
                                </div>
                                <div class="col-md-4 text-right" style="padding-right: 20px;">
                                    <b>{{service?.ServiceTotalAmount}}</b>
                                </div>
                            </div>
                            <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                                <div class="col-md-2">
                                
                                </div>
                                <div class="col-md-4">
                                    {{subService?.Services}}
                                </div>
                                <div class="col-md-1">
                                    {{subService?.Qty}}
                                </div>
                                <div class="col-md-1 text-right">
                                    *
                                </div>
                                <div class="col-md-2 text-right">
                                    <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                                </div>
                                <div class="col-md-2 text-right">
                                    <span class="tablecol">{{ subService?.Amount}}</span>
                                </div>
                            </div>
                        </div>      
                    </ng-container>
            </ng-container>
            
            <br />
            <hr class="my-2 border border-dark" />
            
            <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
            </div>

            <div class="row" style="font-size: 16px;">
            <div class="offset-6 col-3 text-right">
                <b>Approved Amount</b>
            </div>
            <div class="col-3 text-right">
                <b>{{insuranceList.approvalAmount}}</b>
            </div>
            </div>
            
            <div class="row" style="font-size: 16px;" >
                <div class="offset-6 col-3 text-right">
                    <b>Balance Amount</b>
                </div>
                <div class="col-3 text-right">
                   <b>{{balance_amount}}</b> 
                </div>
            </div> 
            <br />
            
            <div> 
            <div>
                <b style="font-size: 17px;">Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1" style="font-size: 17px;">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Credit Amt</b></div>
                <div class="col-md-2"><b>Claim No</b></div>
                <div class="col-md-2"><b>Approval Amt</b></div>
                <div class="col-md-2"><b>Final Bill</b></div>
                
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;">
                <div class="col-md-1">{{insuranceList.RecptNo}}</div>
                <div class="col-md-2">{{insuranceList.RecptDt}}</div>
                <div class="col-md-2">{{billingSummaryDetails?.Gross_Amount}}</div>
                <div class="col-md-2">{{insuranceList.claimNumber}}</div>
                <div class="col-md-2">{{insuranceList.approvalAmount}}</div>
                <div class="col-md-2">{{insuranceList.FinalBill}}</div>
            </div>
            <hr class="my-2 border border-dark" />
            <!-- <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div> -->
            </div>
            
            <hr class="my-2 border border-dark" />
            
            <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
            </div>
            <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
            </b>
            </div>
            <div class="row" style="font-size: 16px;" >
                <div class="col-6 text-left">
                    <div class="row mt-5 mb-3">
                        <div class="col-4"><b>Admission Created By</b></div>
                        <div class="col">{{patientInfoList.admissionCreatedBy}}</div>
                    </div>
                </div>
                <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
                </div>
            </div>
            
            <!-- <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div> -->
            <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt}}
                    </div>
                </div>
            </div>
            </div>
            <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn}}
                    </div>
                </div>
            </div>
            </div>
            
            <!-- <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
            
            </div>
            
             <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
            
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                        </div>
                    </div>
                </div>
            </div> 
            </div> -->
        </div>
        <div class="text-center">
            <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="finalBillPopupClose()" type="submit" value="Close">Close</button>
            <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="insuranceFinalBillPrint()" type="submit" value="Print">Print</button>
        </div>   
    </ng-template>

    <!-- room info start -->
    <ng-template #RoomInfoPopup let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Patient Bed Info</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">X</span>
            </button>
        </div>
        <div class="modal-body">
            <app-patient-room-info [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo"></app-patient-room-info>
        </div>
    </ng-template>
    <!-- room info end -->
</div>
    


<div id="print-prescription-section" style="display: none" class="print-form mt-5"
*ngIf="printPrescription && finalBillPatientInfo !== undefined" style="margin-left: 100px;">
<div class="row no-gutters heading-block">
  <div class="col-md-2 logo">
      <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
  </div>
  <div class="col-md-9">
      <div class="text-center">
          <h2 class="main-title mb-0" [innerHtml]="finalBillPatientInfo?.HospitalName">
          </h2>
          <p>{{finalBillPatientInfo?.HospitalAddress}}</p>
          <h3 class="mb-1 font-weight-bold">
              <u>{{finalBillPatientInfo?.InpatientFinalBIllLabel}}</u>
          </h3>
      </div>
  </div>
</div>
<!-- Patient information -->
<div class="row mt-2">
  <div class="col-md-6">

      <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
          <div class="col-md-3">
              <b>Bill No.</b>
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.BillNo}}
          </div>
      </div>

      <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
          <div class="col-md-3">
              <b>Bill Date </b>
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.BillDate}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              P. Name
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.PatientName}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              DOA
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              Consultant
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.Consultant}}
          </div>
      </div>
      <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
            <div class="col-md-3">
                Referral
            </div>
            <div class="col-md-9">
                <b>:</b> {{finalBillPatientInfo?.Referral}}
            </div>
        </div>
      <div class="row">
          <div class="col-md-3">
              Department
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.Department}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              Address
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.Address}}
          </div>
      </div>
  </div>
  <div class="col-md-6">
      <div class="row">
          <div class="col-md-3">
              <b>Admission No </b>
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
          </div>
      </div>
      <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
          <div class="col-md-3">
              <b>Discharge Date </b>
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              S-W-D-B/O
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.SWDBO}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              UMR No
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.UMRNo}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              Age / Sex
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.AgeSex}}
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">
              Admitted Ward
          </div>
          <div class="col-md-9">
              <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
          </div>
      </div>
  </div>
</div>
<!-- ./END Patient information -->

<div class="mt-2 text-center border border-dark p-2" *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
  <b>Hospitalisation Charges from
      <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
      <span class="pl-2">To</span>
      <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
  </b>
</div>
<ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn">
<div class="row table1">
  <div class="col-12">
      <hr class="my-2 border border-dark" />
  </div>
  <div class="col-md-2">
      Service TypeId
  </div>
  <div class="col-md-4">
      Service Type
  </div>
  <div class="col-md-2">
      Total
  </div>
  <div class="col-md-2">
      <span *ngIf="billServiceDiscountStatus">Discount</span>
  </div>
  <div class="col-md-2">
      Net Amount
  </div>
</div>
<div class="row table1">
  <div class="col-12">
      <hr class="my-2 border border-dark" />
  </div>
  <ng-container *ngFor="let service of finalBillServicesList;">
      <div class="col-md-2">
          {{service?.Service_typeid}}
      </div>
      <div class="col-md-4">
          {{service?.Service_typename}}
      </div>
      <div class="col-md-2">
          {{service?.totalAmount}}
      </div>
      <div class="col-md-2">
          <span *ngIf="billServiceDiscountStatus">{{service?.Discount}}</span>
      </div>
      <div class="col-md-2">
          {{service?.netAmount}}
      </div>
  </ng-container>
</div>
</ng-container> 
<ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
<div class="row table1" >
  <div class="col-12">
      <hr class="my-2 borer border-dark" />
  </div>

  <div class="col-md-1">
      Service Code
  </div>
  <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
      Service Date
  </div>
  <div class="col-md-3">
      Services/Investigation
  </div>
  <div class="col-md-2">
      Qty.
  </div>
  <div class="col-md-2">
      Rate
  </div>
  <div class="col-md-1">
      <span *ngIf="billServiceDiscountStatus">Discount</span>
  </div>
  <div class="col-md-1">
      Amount
  </div>
  <div class="col-12">
      <hr class="my-2 pdf-thick-border" />
  </div>
</div>
<div *ngFor="let service of finalBillServicesList;">
  <!-- <div class="row pdf-blue-text font-weight-bold">
      <div class="col-md-6">
          {{service?.ServiceType}}
      </div>
      <div class="col pdf-blue-text text-right">
          <span>{{service?.ServiceTotalAmount}}</span>
      </div>
  </div> -->

  <div class="row table2">
      <div class="col-md-6 ">
          <b>{{service?.ServiceType}}</b>
      </div>
      <div class="offset-5 col-md-2">
          <b>{{service?.ServiceTotalAmount}}</b>
      </div>
  </div>

  <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
      <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
          <div class="col-md-1">
              {{subService?.ServiceCode}}
          </div>
          <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
              {{subService?.ServiceBillDate}}
          </div>
          <div class="col-md-3">
              {{subService?.Services}}
          </div>
          <div class="col-md-1">
              {{subService?.Qty}}
          </div>
          <div class="col-md-1">
              *
          </div>
          <div class="col-md-2">
              <span class="tablecol">{{subService?.Rate}}</span>
          </div>
          <div class="col-md-1">
              <span *ngIf="billServiceDiscountStatus">{{subService?.Discount}}</span>
          </div>
          <div class="col-md-1">
              <span class="tablecol">{{ subService?.Amount}}</span>
          </div>
      </div>
  </ng-container>
</div>
</ng-container>

<br />
<hr class="my-2 border border-dark" />

<div class="row" *ngIf="billServiceListTotal !== 0">
  <div class="offset-6 col-3">
      <b>Gross Amount</b>
  </div>
  <div class="col-3 text-center">
      <b>{{billingSummaryDetails?.Total_Bill_Amount}}</b>
  </div>
</div>
<div class="row" *ngIf="billReceiptsTotal !== 0">
  <div class="offset-6 col-3">
      <b>Receipt Amount</b>
  </div>
  <div class="col-3 text-center">
      <b>{{billReceiptsTotal}}</b>
  </div>
</div>

<div class="row" *ngIf="billingSummaryDetails?.Discount > 0">
    <div class="offset-6 col-3">
        <b>Discount Amount</b>
    </div>
    <div class="col-3 text-center">
        <b>{{billingSummaryDetails?.Discount}}</b>
    </div>
</div>

<div class="row" *ngIf="finalBillSummary?.Balance_Amount < 0">
    <div class="offset-6 col-3">
        <b>{{ finalBillSummary?.Balance_label }}</b>
    </div>
    <div class="col-3 text-center">
        <b>{{ finalBillSummary?.Balance_Amount }}</b>
    </div>
</div>
<br />

<div *ngIf="finalBillReceiptsList?.length !== 0">
  <div>
      <b>Receipt / Payment Details</b>
  </div>
  <hr class="my-2 border border-dark" />
  <div class="row table1">
      <div class="col-md-1"><b>Recpt. No</b></div>
      <div class="col-md-2"><b>Recpt. Dt.</b></div>
      <div class="col-md-2"><b>Cash Amt</b></div>
      <div class="col-md-2"><b>Cheque Amt</b></div>
      <div class="col-md-1"><b>Card/ Wallet Amt</b></div>
      <div class="col-md-2"><b>Recpt. Amt.</b></div>
      <div class="col-md-1"><b>Remarks</b></div>
  </div>
  <hr class="my-2 border border-dark" />
  <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
      <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
      <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
      <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
      <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
      <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
      <div class="col-md-2">{{receptInfo?.RecptAmt}}</div>
      <div class="col-md-1">
          <span *ngIf="receptInfo?.Remarks !== ''">
              {{receptInfo?.Remarks}}:</span>
      </div>
  </div>
  <hr class="my-2 border border-dark" />
  <div class="row" *ngIf="billReceiptsTotal !== 0">
      <div class="offset-6 col-3"><b>Total</b></div>
      <div class="col-3"><b>{{billReceiptsTotal}}</b></div>
  </div>
</div>

<hr class="my-2 border border-dark" />

<div>
  <b style="text-transform: capitalize;" *ngIf="billReceiptsTotalWords">Total Received Amount in Words: {{billReceiptsTotalWords}}</b>
</div>
<div>
  <b style="text-transform: capitalize;" *ngIf="billServiceListTotalWords">Gross Amount in Words: {{billServiceListTotalWords}}
  </b>
</div>
<div class="text-right">
  <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
</div>
<div class="row">
  <div class="col-6">
      <div class="row">
          <div class="col-4">
              <b>Prepared By</b>
          </div>
          <div class="col">
              <span
                  *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
              <span
                  *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
          </div>
      </div>
  </div>
  <div class="col-6">
      <div class="row">
          <div class="col-4">
              <b>Prepared Dt</b>
          </div>
          <div class="col">
              {{finalBillFooterAcknowledgement?.PreparedDt}}
          </div>
      </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
      <div class="row">
          <div class="col-4">
              <b>Printed By</b>
          </div>
          <div class="col">
              {{userInfo?.username}}
          </div>
      </div>
  </div>
  <div class="col-6">
      <div class="row">
          <div class="col-4">
              <b>Printed On</b>
          </div>
          <div class="col">
              {{finalBillFooterAcknowledgement?.PrintedOn}}
          </div>
      </div>
  </div>
</div>

<div *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
  <div class="mt-5">
      <div class="text-center">
          <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
      </div>
  </div>
  <div class="row my-5">
      <div class="col-8"></div>
      <div class="col-4">
          <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
          <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
      </div>

  </div>

  <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
      and I agree
      and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
      i
      acknowledge the bill.</p>

  <div class="text-center mt-5">
      <div class="row justify-content-center">
          <div class="col-7">
              <div class="row my-4">
                  <div class="col-3">Signature</div>
                  <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
              </div>
              <div class="row my-4">
                  <div class="col-3">Name</div>
                  <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
              </div>
              <div class="row my-4">
                  <div class="col-3">Ph No</div>
                  <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
              </div>
              <div class="row my-4">
                  <div class="col-3">Date</div>
                  <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
              </div>
          </div>
      </div>
  </div>
</div>
</div>

<div id="interim-print-section" style="display: none" class="print-form mt-5">
    <div>
        <h4 style="text-align: center;">Interim Bill</h4>
    </div>
    <hr>
    <div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        Patient Name
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        DOA
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Consultant
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Consultant}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <b>UMR No</b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Department
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Admitted Ward
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Address
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        <b>Admission No </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Relation
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Relation}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Age /Sex
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AgeSex}}
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-5">
                        TPA Company
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.mainPayeeName}}
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-5">
                        <b>Main Payee Name </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PayeeName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Referral
                    </div>
                    <div class="col-md-7">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{patientInfoList.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{patientInfoList.Referral}}({{patientInfoList.ReferralCity}})
                        </div>                
                    </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2"  style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container>
            <div>
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-2 text-center">
                        <span>Discount</span>
                    </div>
                    <div class="col-md-1 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
                 <ng-container>
                    <div *ngFor="let service of finalBillServicesList">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                                {{subService?.ServiceCode}}
                            </div>
                            <div class="col-md-3">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <div class="col-md-2 text-center">
                                <span>{{subService?.Discount}}</span>
                            </div>
                            <div class="col-md-1 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
        <div class="offset-6 col-3 text-right">
            <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
        </div>
        <div class="col-3 text-right">
            <b>{{billingSummaryDetails?.Gross_Amount}}</b>
        </div>
        </div>
        <div class="row" style="font-size: 16px;" >
            <div class="offset-6 col-3 text-right">
                <b>Approved Amount</b>
            </div>
            <div class="col-3 text-right">
                <b>{{insuranceList.approvalAmount}}</b>
            </div>
            </div>
            
            <div class="row" style="font-size: 16px;" >
                <div class="offset-6 col-3 text-right">
                    <b>Balance Amount</b>
                </div>
                <div class="col-3 text-right">
                    {{balance_amount}}
                </div>
            </div> 
        <br />
        
        <div>
        <div>
            <b style="font-size: 17px;">Receipt / Payment Details</b>
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row table1" style="font-size: 17px;">
            <div class="col-md-1"><b>Recpt. No</b></div>
            <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
            <div class="col-md-2"><b>Credit Amt</b></div>
            <div class="col-md-2"><b>Claim No</b></div>
            <div class="col-md-2"><b>Approval Amt</b></div>
            <div class="col-md-2"><b>Interim Bill</b></div>
            
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row" style="font-size: 16px;">
            <div class="col-md-1">{{insuranceList.RecptNo}}</div>
            <div class="col-md-2">{{insuranceList.RecptDt}}</div>
            <div class="col-md-2">{{billingSummaryDetails?.Gross_Amount}}</div>
            <div class="col-md-2">{{insuranceList.claimNumber}}</div>
            <div class="col-md-2">{{insuranceList.approvalAmount}}</div>
            <div class="col-md-2">{{insuranceList.InterimBill}}</div>            
        </div>
        <hr class="my-2 border border-dark" />
        <!-- <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Total_Receipt_Amount > 0">
            <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
            <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
        </div> -->
        </div>
        
        <hr class="my-2 border border-dark" />
        
         <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>

        <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}} </b>
        </div>

        <div class="row" style="font-size: 16px;">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{patientInfoList.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <!-- <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
        <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div> -->
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared By</b>
                </div>
                <div class="col">
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared Dt</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PreparedDt}}
                </div>
            </div>
        </div>
        </div>
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed By</b>
                </div>
                <div class="col">
                    {{userInfo?.username}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed On</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PrintedOn}}
                </div>
            </div>
        </div>
        </div>
        
        <!-- <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
        <div class="mt-5">
            <div class="text-center">
                <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-8"></div>
            <div class="col-4">
                <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
            </div>
        
        </div>
        
        <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
            and I agree
            and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
            i
            acknowledge the bill.</p>
        
        <div class="text-center mt-5">
            <div class="row justify-content-center">
                <div class="col-7">
                    <div class="row my-4">
                        <div class="col-3">Signature</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Name</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Ph No</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Date</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
    </div>
</div>

<div id="finalBill-print-section" style="display: none" class="print-form mt-5">
    <div>
        <h4 style="text-align: center;">Final Bill</h4>
    </div><hr>
    <div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        Patient Name
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        DOA
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Consultant
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Consultant}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <b>UMR No</b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Department
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Admitted Ward
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Discharge Date
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{insuranceList.dischargeDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Address
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5">
                        <b>Admission No </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Relation
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.Relation}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        Age /Sex
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.AgeSex}}
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-5">
                        TPA Company
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.mainPayeeName}}
                    </div>
                </div>
                <div class="row" >
                    <div class="col-md-5">
                        <b>Main Payee Name </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.PayeeName}}
                    </div>
                </div>
                <div class="row" >
                    <div class="col-md-5">
                        <b>Bill No </b>
                    </div>
                    <div class="col-md-7">
                        <b>:</b> {{patientInfoList.BillNo}}
                    </div>
                </div>
                <div class="row" > 
                    <div class="col-md-5">
                        Referral
                    </div>
                    <div class="col-md-7">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{patientInfoList.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{patientInfoList.Referral}}({{patientInfoList.ReferralCity}})
                        </div>                
                    </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2"  style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{patientInfoList.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container>
            <div>
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Category
                    </div>
                    <div class="col-md-4">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-2 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div>                 
                <ng-container>
                    <div *ngFor="let service of finalBillServicesList;">
                        <div class="row table2" style="font-size: 17px;">            
                            <div class="col-md-6 ">
                                <b>{{service?.ServiceType}}</b>
                            </div>
                            <div class="col-md-4 text-right" style="padding-right: 20px;">
                                <b>{{service?.ServiceTotalAmount}}</b>
                            </div>
                        </div>
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                            
                            </div>
                            <div class="col-md-4">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
        <div class="offset-6 col-3 text-right">
            <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
        </div>
        <div class="col-3 text-right">
            <b>{{billingSummaryDetails?.Gross_Amount}}</b>
        </div>
        </div>
        <div class="row" style="font-size: 16px;" >
            <div class="offset-6 col-3 text-right">
                <b>Approved Amount</b>
            </div>
            <div class="col-3 text-right">
                <b>{{insuranceList.approvalAmount}}</b>
            </div>
            </div>
            
            <div class="row" style="font-size: 16px;">
                <div class="offset-6 col-3 text-right">
                    <b>Balance Amount</b>
                </div>
                <div class="col-3 text-right">
                    {{balance_amount}}
                </div>
            </div> 
        <br />
        
        <div >
        <div>
            <b style="font-size: 17px;">Receipt / Payment Details</b>
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row table1" style="font-size: 17px;">
            <div class="col-md-1"><b>Recpt. No</b></div>
            <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
            <div class="col-md-2"><b>Credit Amt</b></div>
            <div class="col-md-2"><b>Claim No</b></div>
            <div class="col-md-2"><b>Approval Amt</b></div>
            <div class="col-md-2"><b>Final Bill</b></div>
            
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row" style="font-size: 16px;">
            <div class="col-md-1">{{insuranceList.RecptNo}}</div>
            <div class="col-md-2">{{insuranceList.RecptDt}}</div>
            <div class="col-md-2">{{billingSummaryDetails?.Gross_Amount}}</div>
            <div class="col-md-2">{{insuranceList.claimNumber}}</div>
            <div class="col-md-2">{{insuranceList.approvalAmount}}</div>
            <div class="col-md-2">{{insuranceList.FinalBill}}</div>
        </div>
        <hr class="my-2 border border-dark" />
        <!-- <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Total_Receipt_Amount > 0">
            <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
            <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
        </div> -->
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>
        <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
        </b>
        </div>
        <div class="row" style="font-size: 16px;" >
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{patientInfoList.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <!-- <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
        <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div> -->
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared By</b>
                </div>
                <div class="col">
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared Dt</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PreparedDt}}
                </div>
            </div>
        </div>
        </div>
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed By</b>
                </div>
                <div class="col">
                    {{userInfo?.username}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed On</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PrintedOn}}
                </div>
            </div>
        </div>
        </div>
        
        <!-- <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
        <div class="mt-5">
            <div class="text-center">
                <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-8"></div>
            <div class="col-4">
                <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
            </div>
        
        </div>
        
        <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
            and I agree
            and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
            i
            acknowledge the bill.</p>
        
        <div class="text-center mt-5">
            <div class="row justify-content-center">
                <div class="col-7">
                    <div class="row my-4">
                        <div class="col-3">Signature</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Name</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Ph No</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Date</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                </div>
            </div>
        </div>
        </div> -->
    </div>
</div>

